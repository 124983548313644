import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { AdditionalRules } from 'types/common';

const StyledLink = styled(Link)<{ $highlight?: boolean } & AdditionalRules>`
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    text-decoration: none;
    letter-spacing: -0.19px;
    margin: 0 16px;
    color: ${({ $highlight }) =>
        $highlight ? 'var(--highlight-blue)' : 'var(--grey__dark)'};
    ${({ additional }) => additional}
`;

export default StyledLink;
