import styled from 'styled-components';
import { H3Medium, Title3Regular } from '../../components/styled/typography';
import { useTranslation } from 'react-i18next';
import screenRelativeTo1440 from '../../components/styled/utility/screenRelativeTo1440';
import { Link as BaseLink } from 'react-router-dom';
import { routesPaths } from '../../config/routes';
import media from 'styled-media-query';

const Container = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--primary__x-light);
    padding: 100px ${screenRelativeTo1440('120px')};

    ${media.lessThan('medium')`
    padding: 50px 10px 40px 10px;
    `}
`;

const Title = styled(H3Medium)`
    ${media.lessThan('medium')`
    font-size: 32px;
    `}
`;

const Body = styled(Title3Regular)`
    white-space: pre-line;
    margin-top: 40px;
    text-align: center;

    ${media.lessThan('medium')`
    font-size: 18px;
    margin-top: 0;
    `}
`;

const Link = styled(BaseLink)`
    margin-top: 72px;
    background: var(--grey__dark);
    color: white;
    width: 260px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;

    ${media.lessThan('medium')`
    margin-top: 20px;
    `}
`;

const Teams = () => {
    const { t } = useTranslation();
    return (
        <Container>
            <Title>{t('about_us.teams.title')}</Title>
            <Body>{t('about_us.teams.body')}</Body>
            <Link to={routesPaths.careers}>{t('about_us.teams.button')}</Link>
        </Container>
    );
};

export default Teams;
