import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
    SubtitleRegular,
    Title1Bold,
    Title1Medium,
    Title2Bold,
    Title2Medium,
} from 'components/styled/typography';
import Icon from '../../components/svg-icon/Icon';
import { IconType } from '../../components/svg-icon/icon-types';
import screenRelativeTo1440 from '../../components/styled/utility/screenRelativeTo1440';
import media from 'styled-media-query';
import useIsMobileView from '../../common/hooks/useIsMobileView';

const Container = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 ${screenRelativeTo1440('120px')};
    margin-top: 110px;

    ${media.lessThan('medium')`
    margin-top: 64px;
    `}
`;

const Header = styled.div`
    display: flex;
    margin: 0 auto;
`;

const FeatureList = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    flex-direction: column;
    align-items: center;
    margin-top: 56px;
    `}
`;

const Card = styled.div`
    display: flex;
    box-sizing: border-box;
    width: 280px;
    flex-direction: column;
    padding: 60px 20px 0;
    margin-bottom: 70px;
    margin-right: calc((100% - (280px * 3)) / 2);

    &:nth-child(3) {
        margin-right: 0;
    }

    ${media.lessThan('medium')`
    margin-right: 0;
    width: 80%;
    text-align: center;
    margin-bottom: 40px;
    padding: unset;
    `}
`;

const CardHeader = styled.div`
    position: relative;
    padding: 60px 20px 0;

    ${media.lessThan('medium')`
    margin-right: auto;
    margin-left: auto;
    `}
`;

const CardTitle = styled(Title2Bold)`
    z-index: 10;
    white-space: nowrap;
    color: var(--grey__medium);

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    font-size: 24px;
    `}
`;

const CardHeaderCircle = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 100%;
    height: 100px;
    width: 100px;
    background: var(--grey__thin);
    opacity: 0.8;

    ${media.lessThan('medium')`
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    `}
`;

const CardHeaderIcon = styled(Icon).attrs(() => ({
    size: '48px',
}))`
    position: absolute;
    left: 24px;
    top: 6px;
    fill: var(--primary__default);

    ${media.lessThan('medium')`
    left: calc(50% - 24px);
    `}
`;

const CardBody = styled(SubtitleRegular)`
    width: 275px;
    margin-top: 30px;
    padding-left: 20px;

    ${media.lessThan('medium')`
    width: 100%;
    padding-left: unset;
    font-size: 16px;
    `}
`;

const Features = () => {
    const { t } = useTranslation();

    const isMobileView = useIsMobileView();
    return (
        <Container>
            <Header>
                {isMobileView ? (
                    <Title2Medium
                        additional={{ textAlign: 'center', fontSize: '24px' }}
                    >
                        {t('for_business.features.title.0') +
                            ' ' +
                            t('for_business.features.title.1')}
                    </Title2Medium>
                ) : (
                    <>
                        <Title1Medium additional={{ marginRight: '8px' }}>
                            {t('for_business.features.title.0')}
                        </Title1Medium>
                        <Title1Bold>
                            {t('for_business.features.title.1')}
                        </Title1Bold>
                    </>
                )}
            </Header>
            <FeatureList>
                {(
                    [
                        'payment--yellow',
                        'double-plus',
                        'upfront',
                        'new-customers',
                        'no-risk',
                    ] as IconType[]
                ).map((icon, index) => (
                    <Card key={icon}>
                        <CardHeader>
                            <CardTitle>
                                {t(
                                    `for_business.features.cards.${index}.title`
                                )}
                            </CardTitle>
                            <CardHeaderCircle />
                            <CardHeaderIcon name={icon} />
                        </CardHeader>
                        <CardBody>
                            {t(`for_business.features.cards.${index}.body`)}
                        </CardBody>
                    </Card>
                ))}
            </FeatureList>
        </Container>
    );
};

export default Features;
