import styled from 'styled-components';
import {
    H3Medium,
    H3Regular,
    SubtitleBold,
    Title2Medium,
    Title2Regular,
    Title3Medium,
} from '../../components/styled/typography';
import CenteredActionCall from '../../components/action-call/CenteredActionCall';
import { useTranslation } from 'react-i18next';
import BackgroundCircle from 'components/styled/BackgroundCircle';
import screenRelativeTo1440 from '../../components/styled/utility/screenRelativeTo1440';
import useIsMobileView from '../../common/hooks/useIsMobileView';
import media from 'styled-media-query';

const Container = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--primary__x-light);
    padding: 68px 0 76px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    ${media.lessThan('medium')`
    padding: 68px 30px 76px 30px; 
    `}
`;

const H3MediumCentered = styled(Title2Medium)`
    text-align: center;
`;

const CareersTitle = () => {
    const { t } = useTranslation();
    const isMobileView = useIsMobileView();

    return (
        <Container>
            <CenteredActionCall
                firstLine={{
                    component: isMobileView ? H3MediumCentered : H3Medium,
                    text: t('careers.title.lines.0'),
                }}
                secondLine={{
                    component: isMobileView ? Title2Regular : H3Regular,
                    text: t('careers.title.lines.1'),
                }}
                thirdLine={{
                    firstPart: {
                        component: isMobileView ? SubtitleBold : Title3Medium,
                        text: t('careers.title.lines.2'),
                    },
                }}
            />
            {!isMobileView && (
                <BackgroundCircle
                    size="103px"
                    color="var(--primary__light)"
                    additional={{
                        bottom: '70px',
                        right: screenRelativeTo1440('370px'),
                    }}
                />
            )}
        </Container>
    );
};

export default CareersTitle;
