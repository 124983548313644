interface IAppConfig {
    cdnUrl: string;
    userDashboardUrl: string;
    merchantDashboardUrl: string;
    oauthApiUrl: string;
    oauthClientId: string;
    envValue: string;
}

export default (): IAppConfig => ({
    cdnUrl: process.env.REACT_APP_COMMON_CDN_URL || '',
    userDashboardUrl: process.env.REACT_APP_USER_DASHBOARD || '',
    merchantDashboardUrl: process.env.REACT_APP_MERCHANT_DASHBOARD || '',
    oauthApiUrl: process.env.REACT_APP_OAUTH_API_URL || '',
    oauthClientId: process.env.REACT_APP_OAUTH_CLIENT_ID || '',
    envValue: process.env.REACT_APP_ENV_VALUE || '',
});
