import styled from 'styled-components';
import { FooterLinkGroupItem } from './types';
import { mediaQuery } from '@jouri/components';

const FooterLinkGroup = (props: FooterLinkGroupItem) => (
    <FooterLinkGroupWrapper>
        <FooterLinkGroupTitle>{props.title}</FooterLinkGroupTitle>
        <FooterLinkGroupNav>
            <ul>
                {props.links.map((link, index) => (
                    <li key={index}>
                        <a
                            target={link.external ? '_blank' : '_self'}
                            href={link.href}
                        >
                            {link.label}
                        </a>
                    </li>
                ))}
            </ul>
        </FooterLinkGroupNav>
    </FooterLinkGroupWrapper>
);

const FooterLinkGroupWrapper = styled.div`
    color: #ffffff;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    row-gap: 36px;
    letter-spacing: -0.19px;

    ${mediaQuery.lessThan('tablet')`
        row-gap: 22px;
        font-weight: 300;
    `}
`;

const FooterLinkGroupTitle = styled.div`
    font-weight: 700;
`;

const FooterLinkGroupNav = styled.nav`
    ul {
        display: flex;
        flex-direction: column;
        row-gap: 2px;

        > li > a {
            color: #ffffff;
            text-decoration: none;
            white-space: nowrap;

            &:hover {
                color: #40a9ff;
                text-decoration: underline;
            }
        }
    }
`;

export default FooterLinkGroup;
