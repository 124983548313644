import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AppRoutes from 'components/app-routes';

import 'pure-react-carousel/dist/react-carousel.es.css';

import 'assets/style/colors.css';
import 'assets/style/default.css';
import 'assets/style/reset.css';
import { withRTLSupport } from 'common/rtl';
import GlobalStyles from 'theme/GlobalStyles';

const App = () => {
    const location = useLocation();

    useEffect(() => {
        document.getElementById('root')?.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <GlobalStyles />
            <AppRoutes />
        </>
    );
};

export default withRTLSupport(App);
