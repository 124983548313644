import useOptionalLayout from 'common/hooks/useOptionalLayout';
import BaseLayout from 'components/layouts/base-layout';
import CategoryOverview from './CategoryOverview';
import FrequentlyAskedQuestionsTitle from './FrequentlyAskedQuestionsTitle';
import Questions from './Questions';

const FrequentlyAskedQuestions = () => {
    const { Layout } = useOptionalLayout(BaseLayout);

    return (
        <Layout>
            <FrequentlyAskedQuestionsTitle />
            <CategoryOverview />
            <Questions />
        </Layout>
    );
};

export default FrequentlyAskedQuestions;
