import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Title2Medium } from '../../components/styled/typography';
import UnderlinedTextStyle from '../../components/styled/css/UnderlinedTextStyle';
import media from 'styled-media-query';
import useIsMobileView from '../../common/hooks/useIsMobileView';

const Container = styled.section`
    padding: 88px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${media.lessThan('medium')`
   padding: 20px 0;
    `}
`;

const Title = styled(Title2Medium)`
    ${UnderlinedTextStyle}
`;

const Punchline = () => {
    const { t } = useTranslation();
    const isMobileView = useIsMobileView();

    return (
        <Container>
            <Title
                underline={{
                    color: 'var(--secondary__medium)',
                    width: '58px',
                    height: isMobileView ? '6px' : '10px',
                    bottom: isMobileView ? '-6px' : undefined,
                }}
            >
                {t('careers.current_jobs.title')}
            </Title>
        </Container>
    );
};

export default Punchline;
