import { useLocation, useParams } from 'react-router-dom';
import CareersTitle from '../careers/CareersTitle';
import { useEffect, useState } from 'react';
import JobDescription from './JobDescription';
import { Entity } from '../../components/entity/Details';
import ApplicationForm from './ApplicationForm';
import useJobsApi from '../../api/jobs/jobs.api';
import { ApiResponseStatus } from '../../common/http/http.model';

const JobApplication = () => {
    const jobsApi = useJobsApi();
    const { id } = useParams();
    const { state } = useLocation();
    const [entity, setEntity] = useState<Entity>(JSON.parse(state as string));

    useEffect(() => {
        (async () => {
            if (id) {
                const response = await jobsApi.byId(id);

                if (
                    response.status === ApiResponseStatus.Success &&
                    response.result
                ) {
                    setEntity(response.result);
                }
            }
        })();
    }, []);

    return (
        <>
            <CareersTitle />
            {entity && (
                <>
                    <JobDescription entity={entity} />
                    <ApplicationForm entity={entity} />
                </>
            )}
        </>
    );
};

export default JobApplication;
