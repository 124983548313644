import { ApiResponseStatus } from '../../common/http/http.model';
import { useRef } from 'react';
import { Job, JobsApiCalls, JobsByCategory, Section } from './jobs.model';
import JobsApiEndpoint from './jobs.endpoint';

const separateSectionBodyToList = (body: string): string => {
    const itemCircleSymbol = '●';
    const newLineSymbol = '\n';
    return body
        .split(newLineSymbol)
        .filter((bodyPart) => bodyPart)
        .map((bodyPart) => `${itemCircleSymbol} ${bodyPart}\n`)
        .join('');
};

const processSection = (section: Section) => ({
    ...section,
    body:
        section.isList && section.body
            ? separateSectionBodyToList(section.body)
            : section.body,
});

const processJob = (job: Job) => ({
    ...job,
    sections: job.sections.map(processSection),
});

const processJobCategories = (categories: JobsByCategory) => {
    return Object.keys(categories).reduce(
        (categories: JobsByCategory, categoryKey: string) => ({
            ...categories,
            [categoryKey]: categories[categoryKey].map(processJob),
        }),
        categories
    );
};

const useJobsApi = (): JobsApiCalls => {
    const all = async () => {
        const response = await fetch(JobsApiEndpoint.All);
        const result = response.ok ? await response.json() : [];

        return {
            result: processJobCategories(result),
            status: response.ok
                ? ApiResponseStatus.Success
                : ApiResponseStatus.Error,
        };
    };
    const byId = async (jobId: string) => {
        let foundJob: Job | undefined = undefined;
        const response = await all();

        if (response.status === ApiResponseStatus.Success) {
            const allJobs = response.result;
            for (const category in allJobs) {
                foundJob = allJobs[category].find(
                    (job: Job) => job.id === jobId
                );

                if (foundJob) {
                    break;
                }
            }
        }

        return {
            status: foundJob
                ? ApiResponseStatus.Success
                : ApiResponseStatus.Error,
            result: foundJob ?? null,
        };
    };
    const generateApiCalls = (): JobsApiCalls => {
        return {
            all,
            byId,
        };
    };
    const ref = useRef<JobsApiCalls>(generateApiCalls());

    return ref.current;
};

export default useJobsApi;
