import TextWithCircle from 'components/text-with-circle/TextWithCircle';
import styled, { StyledComponent } from 'styled-components';
import media from 'styled-media-query';

const TitleFirstLine = styled(TextWithCircle).attrs<{
    left?: number;
    top?: number;
    size?: number;
}>(({ left, top, size }) => ({
    circle: {
        left: left ?? -16,
        top: top ?? 8,
        size: size ?? 38,
    },
}))`
    z-index: 10;

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    text-align: center;    
    `}
`;

export const TitleSecondLine = styled.div`
    margin-top: 14px;
    z-index: 10;

    ${media.lessThan('medium')`
     text-align: center;
    `}
`;

const TitleThirdLine = styled.div`
    margin-top: 14px;

    ${media.lessThan('medium')`
     text-align: center;
    `}
`;

type ComponentWithText = {
    component: StyledComponent<any, any>;
    text: string;
};

interface CenteredTitleProps {
    firstLine: ComponentWithText;
    secondLine: ComponentWithText;
    thirdLine?: {
        firstPart: ComponentWithText;
        secondPart?: ComponentWithText;
    };
    firstLineCircleProps?: {
        left?: number;
        top?: number;
        size?: number;
    };
}

const CenteredActionCall = ({
    firstLine,
    secondLine,
    thirdLine,
    firstLineCircleProps,
}: CenteredTitleProps) => {
    const FirstLine = firstLine.component;
    const SecondLine = secondLine.component;
    const ThirdLinePartOne = thirdLine?.firstPart.component;
    const ThirdLinePartTwo = thirdLine?.secondPart?.component;

    return (
        <>
            <TitleFirstLine
                circle={{
                    top: firstLineCircleProps?.top,
                    left: firstLineCircleProps?.left,
                    size: firstLineCircleProps?.size,
                }}
            >
                <FirstLine>{firstLine.text}</FirstLine>
            </TitleFirstLine>
            <TitleSecondLine>
                <SecondLine>{secondLine.text}</SecondLine>
            </TitleSecondLine>
            {thirdLine && (
                <TitleThirdLine>
                    {ThirdLinePartOne && (
                        <ThirdLinePartOne additional={{ marginRight: '8px' }}>
                            {thirdLine.firstPart.text}
                        </ThirdLinePartOne>
                    )}
                    {thirdLine.secondPart && (
                        <TextWithCircle
                            circle={{
                                size: 128,
                                top: -48,
                                left: 0,
                                color: 'var(--primary__light)',
                            }}
                            isSvg={false}
                            additional={{ display: 'inline-block' }}
                        >
                            {ThirdLinePartTwo && (
                                <ThirdLinePartTwo>
                                    {thirdLine.secondPart.text}
                                </ThirdLinePartTwo>
                            )}
                        </TextWithCircle>
                    )}
                </TitleThirdLine>
            )}
        </>
    );
};

export default CenteredActionCall;
