import styled from 'styled-components';
import { SubtitleBold, Title2Bold } from '../../components/styled/typography';
import { useTranslation } from 'react-i18next';
import DomCircle from '../../components/styled/DomCircle';
import Icon from '../../components/svg-icon/Icon';
import { IconType } from '../../components/svg-icon/icon-types';
import screenRelativeTo1440 from '../../components/styled/utility/screenRelativeTo1440';
import media from 'styled-media-query';

const Container = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 54px ${screenRelativeTo1440('120px')} 0;
    background: var(--secondary__thin__50);

    ${media.lessThan('medium')`
    font-size: unset;
    background: white;
    `}
`;

const Title = styled(Title2Bold)`
    ${media.lessThan('medium')`
    font-size: 24px;
    `}
`;

const CardList = styled.div`
    display: flex;
    justify-content: space-between;
    transform: translateY(42px);
    margin-top: 20px;
    width: 100%;

    ${media.lessThan('medium')`
   flex-direction: column;
   align-items: center;
   
   > * {
   margin-bottom: 50px;
   }
   
   
    `}
`;

const Card = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    padding: 46px 0;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    background: white;
    width: 390px;
    box-sizing: border-box;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);

    ${media.lessThan('medium')`
     width: 80%;
     background: var(--secondary__thin__OP40);
    `}
`;

const CardIcon = styled(Icon).attrs(() => ({ size: '32px' }))`
    position: absolute;
    top: -16px;
    left: calc(50% - 16px);
    z-index: 10;
`;

const CardCircle = styled(DomCircle).attrs(() => ({
    size: '56px',
    color: 'var(--secondary__default)',
}))`
    top: -28px;
    left: calc(50% - 28px);
`;

const CardBody = styled(SubtitleBold)``;

const CategoryOverview = () => {
    const { t } = useTranslation();
    return (
        <Container>
            <Title>{t('frequently_asked_questions.answers.title')}</Title>
            <CardList>
                {(['product', 'payment', 'security'] as IconType[]).map(
                    (icon, index) => (
                        <Card>
                            <CardIcon name={icon} />
                            <CardCircle />
                            <CardBody>
                                {t(
                                    `frequently_asked_questions.answers.cards.${index}`
                                )}
                            </CardBody>
                        </Card>
                    )
                )}
            </CardList>
        </Container>
    );
};

export default CategoryOverview;
