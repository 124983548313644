import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import i18n from 'assets/i18n';
import { BrowserRouter } from 'react-router-dom';
import SpriteSVG from 'components/svg-icon/SpriteSVG';
import { ThemeProvider } from '@jouri/components';

void i18n.init();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ThemeProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
            <SpriteSVG />
        </ThemeProvider>
    </React.StrictMode>
);
