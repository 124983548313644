import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import media from 'styled-media-query';
import useIsMobileView from '../../common/hooks/useIsMobileView';
import EntityList from '../../components/category/EntityList';

const Container = styled.section`
    display: flex;
    flex-direction: column;
    padding: 140px 0 0;

    ${media.lessThan('medium')`
     padding: 0 0 0 0;
    `}
`;

const ProductCategory = styled(EntityList).attrs(() => ({ border: true }))`
    --category__accent: var(--secondary__default);
    --category__background: white;
    --category__text-color: var(--grey__dark);
    --category__question-color--active: var(--grey__dark);
    --category__answer-color: var(--grey__dark);
    --category__circle-color: var(--secondary__default);
    padding-top: 90px;
    padding-bottom: 80px;

    ${media.lessThan('medium')`
        padding: 40px 20px 40px 20px;

    `}
`;

const RepaymentCategory = styled(EntityList).attrs(({ iconName }) => ({
    category: 'repayment_and_chargeback',
    questionCount: 10,
    iconName: iconName,
    border: true,
}))`
    --category__accent: var(--primary__default);
    --category__background: var(--grey__light);
    --category__text-color: white;
    --category__question-color--active: var(--primary__default);
    --category__answer-color: white;
    --category__circle-color: var(--primary__dark);
    padding-top: 90px;
    padding-bottom: 80px;

    ${media.lessThan('medium')`

    padding: 40px 20px 40px 20px;
    `}
`;

const AccountCategory = styled(ProductCategory)`
    ${media.lessThan('medium')`
    padding-left: 20px;
    padding-right: 25px;
    `}
`;

type QA = {
    question: string;
    answer: string;
};

const Questions = () => {
    const { t } = useTranslation();
    const isMobileView = useIsMobileView();

    const getQAData = (category: string) => {
        const data: QA[] = t(
            `frequently_asked_questions.questions.categories.${category}.questions_and_answers`,
            { returnObjects: true }
        );

        return {
            title: t(
                `frequently_asked_questions.questions.categories.${category}.title`
            ),
            entities: data.map(({ question, answer }) => ({
                title: question,
                sections: [{ body: answer }],
            })),
        };
    };

    const productAndService = getQAData('product_and_service');
    const repaymentAndChargeBack = getQAData('repayment_and_chargeback');
    const accountSettingsAndSecurity = getQAData(
        'account_settings_and_security'
    );

    return (
        <Container>
            <ProductCategory
                entities={productAndService.entities}
                title={productAndService.title}
                iconName={!isMobileView ? 'plus--dark' : undefined}
            />
            <RepaymentCategory
                entities={repaymentAndChargeBack.entities}
                title={repaymentAndChargeBack.title}
                iconName={!isMobileView ? 'plus--dark' : undefined}
            />
            <AccountCategory
                entities={accountSettingsAndSecurity.entities}
                title={accountSettingsAndSecurity.title}
                iconName={!isMobileView ? 'plus--dark' : undefined}
            />
        </Container>
    );
};

export default Questions;
