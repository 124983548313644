import {
    BYPASS_AUTH,
    NO_BASE_LAYOUT,
} from 'common/constants/queryStringParams';
import { coerceBooleanURLParam } from 'common/utils/coerce';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function useShouldBypassAuth() {
    const [searchParams] = useSearchParams();
    const bypassAuth = useMemo(
        () =>
            coerceBooleanURLParam(searchParams.get(NO_BASE_LAYOUT)) ||
            coerceBooleanURLParam(searchParams.get(BYPASS_AUTH)),
        [searchParams]
    );

    return bypassAuth;
}
