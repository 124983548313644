import CareersTitle from './CareersTitle';
import Punchline from './Punchline';
import Jobs from './Jobs';

const Careers = () => {
    return (
        <>
            <CareersTitle />
            <Punchline />
            <Jobs />
        </>
    );
};

export default Careers;
