import {
    Body1Medium,
    Title1Medium,
    Title2Medium,
    Title3Medium,
} from 'components/styled/typography';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import TextWithCircle from 'components/text-with-circle/TextWithCircle';
import Icon from '../../components/svg-icon/Icon';
import screenRelativeTo1440 from '../../components/styled/utility/screenRelativeTo1440';
import media from 'styled-media-query';
import useIsMobileView from '../../common/hooks/useIsMobileView';
import i18n from 'assets/i18n';

const Container = styled.section`
    position: relative;
    margin-top: 130px;
    height: 500px;
    width: 100%;
    padding: 30px ${screenRelativeTo1440('140px')};

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    height: unset;
    margin-top: 30px;
    `}
`;

const StepContainer = styled.div`
    position: relative;

    &:before {
        background: var(--primary__thin);
        filter: blur(200px);
        height: 100%;
        width: 100%;
        content: 'background';
        color: transparent;
        position: absolute;
        left: 0;
        right: 0;
    }

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    display: flex;
    flex-direction: column;
    padding: 20px;
    `}
`;

const Step = styled(TextWithCircle)`
    &:not(:last-of-type) {
        margin-bottom: 50px;
    }
`;

const DisplayCircle = styled(Icon).attrs(({ size }) => ({
    size: size ?? '320px',
    name: 'yellow-circle',
}))`
    position: absolute;

    right: ${screenRelativeTo1440('120px')};
    top: -10px;

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    right: 0;
    top: unset;
    position: static;
    `}
`;

const DisplayMessage = styled(Title1Medium)`
    position: absolute;
    width: 168px;
    right: ${screenRelativeTo1440('140px')};
    top: 98px;
`;

const DisplayImage = styled.img.attrs(() => ({
    src:
        process.env.REACT_APP_COMMON_CDN_URL +
        `/web/app-preview${i18n.language === 'ar' ? '-ar' : ''}.png`,
}))`
    border-radius: 30px;
    height: 450px;
    width: 225px;
    position: absolute;
    top: 20px;
    right: ${screenRelativeTo1440('340px')};

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    right: unset;
    `}
`;

const AppPreviewWrapper = styled.div`
    position: relative;
    display: flex;
    min-height: 400px;
    max-width: 375px;
    margin: 30px auto 0 auto;
`;

const CircleWithMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    width: 320px;
    right: 0;

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    width: 100%;
    margin: 0 20px 0 20px
    `}
`;

const DisplayMessageMobileView = styled(Title3Medium)`
    position: absolute;
    right: 20px;
    width: 110px;
    display: block;
`;

const Steps = () => {
    const { t } = useTranslation();
    const isMobileView = useIsMobileView();
    const TitleComponent = isMobileView ? Body1Medium : Title2Medium;

    return (
        <Container>
            <StepContainer>
                {[
                    ['first', 'blue'],
                    ['second', 'red'],
                    ['third', 'yellow'],
                    ['fourth', 'blue'],
                    ['fifth', 'red'],
                ].map(([step, circle]) => (
                    <Step
                        key={step}
                        circle={{
                            size: isMobileView ? 20 : 40,
                            left: isMobileView ? -5 : -25,
                            top: isMobileView ? 4 : 8,
                            color: circle as 'blue' | 'red' | 'yellow',
                        }}
                    >
                        <TitleComponent
                            additional={{
                                position: 'relative',
                                fontWeight: '500',
                                left: isMobileView ? '5px' : 'unset',
                            }}
                        >
                            {t(`home_page.steps.messages.${step}`)}
                        </TitleComponent>
                    </Step>
                ))}
            </StepContainer>

            {isMobileView ? (
                <AppPreviewWrapper>
                    <CircleWithMessage>
                        <DisplayCircle size={'180px'} />
                        <DisplayMessageMobileView>
                            {t('home_page.steps.display_message')}
                        </DisplayMessageMobileView>
                    </CircleWithMessage>

                    <DisplayImage />
                </AppPreviewWrapper>
            ) : (
                <>
                    <DisplayCircle />
                    <DisplayMessage>
                        {t('home_page.steps.display_message')}
                    </DisplayMessage>
                    <DisplayImage />
                </>
            )}
        </Container>
    );
};

export default Steps;
