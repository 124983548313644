import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Body2Regular } from '../styled/typography';

const TextButton = styled.button`
    border: none;
    background-color: inherit;
    cursor: pointer;
`;

const Text = styled(Body2Regular)`
    font-weight: 700;
`;

const ChangeLanguage = () => {
    const { i18n } = useTranslation();
    const language = i18n.language;
    const buttonText = language === 'ar' ? 'English' : 'العربية';
    const changeLanguage = async () => {
        const languageToSet = language === 'ar' ? 'en' : 'ar';
        localStorage.setItem('@preferredLanguage', languageToSet);

        await i18n.changeLanguage(languageToSet);
    };
    return (
        <TextButton onClick={changeLanguage}>
            <Text>{buttonText}</Text>
        </TextButton>
    );
};

export default ChangeLanguage;
