import {
    SubtitleBold,
    SubtitleRegular,
    Title3Medium,
} from '../styled/typography';
import Icon from '../svg-icon/Icon';
import React from 'react';
import styled from 'styled-components';
import BaseButton from '../styled/Button';
import { IconType } from '../svg-icon/icon-types';
import media from 'styled-media-query';

const Summary = styled.summary`
    display: flex;
    justify-content: space-between;
    list-style: none;
    cursor: pointer;

    &::-webkit-details-marker {
        display: none;
    }

    ${Title3Medium} {
        color: var(--category__text-color);
    }

    ${media.lessThan('medium')`
    align-items:center;
    `}
`;

const Button = styled(BaseButton).attrs(() => ({ type: 'button' }))`
    width: 200px;
    background: var(--secondary__medium);
    color: white;
    margin: 70px auto 0;
`;

const Container = styled.details<{ border?: boolean }>`
    padding-left: 16px;

    ${({ border }) =>
        border ? 'border-left: 4px solid var(--grey__thin);' : ''}
    &[open] {
        border-color: var(--category__accent);

        ${Summary} {
            margin-bottom: 32px;

            ${Title3Medium} {
                color: var(--category__question-color--active);
            }
        }
    }
`;

const Content = styled(SubtitleRegular)`
    display: block;
    width: 1000px;

    ${media.lessThan('medium')`
    width: 100%;
    `}
`;

const Section = styled.div`
    white-space: pre-line;
    color: var(--category__text-color);

    & + * {
        margin-top: 70px;
    }
`;

const SectionTitle = styled(SubtitleBold)`
    display: block;
    margin-bottom: 32px;

    &:not(:first-of-type) {
        margin-top: 70px;
    }

    ${media.lessThan('medium')`
    font-size: 18px;
    `}
`;

const SectionBody = styled.pre`
    white-space: pre-wrap;

    ${media.lessThan('medium')`
    font-size: 16px;
    `}
`;

export const SectionList = styled.ul`
    padding-left: 40px;
`;

const SectionListItem = styled.li`
    list-style: disc;

    &:not(:last-child) {
        margin-bottom: 24px;
    }
`;

export interface Entity {
    title: string;
    sections: { title?: string; body?: string; list?: string[] }[];
    action?: { text: string; onClick?: (event: React.MouseEvent) => void };
}

interface DetailsProps {
    entity: Entity;
    iconName?: IconType;
    open?: boolean;
    border?: boolean;
    summary?: boolean;
    action?: boolean;
    className?: string;
}

const Title = styled(Title3Medium)`
    font-size: 24px;

    ${media.lessThan('medium')`
    font-size: 18px;
    `}
`;

const Details = ({
    entity,
    iconName,
    open,
    border,
    summary,
    action,
    className,
}: DetailsProps) => {
    return (
        <Container className={className} open={open} border={border}>
            <Summary>
                {(summary ?? true) && <Title>{entity.title}</Title>}
                {iconName && <Icon name={iconName} size="16px" />}
            </Summary>
            <Content>
                {entity.sections.map((section) => (
                    <Section key={section.title}>
                        {section.title && (
                            <SectionTitle>{section.title}</SectionTitle>
                        )}
                        <SectionBody>{section.body}</SectionBody>
                        {section.list && (
                            <SectionList>
                                {section.list.map((item) => (
                                    <SectionListItem key={item}>
                                        {item}
                                    </SectionListItem>
                                ))}
                            </SectionList>
                        )}
                    </Section>
                ))}
            </Content>
            {entity.action && (action ?? true) && (
                <Button onClick={entity.action.onClick}>
                    {entity.action.text}
                </Button>
            )}
        </Container>
    );
};

export default Details;
