import EntityList from '../../components/category/EntityList';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useJobsApi from '../../api/jobs/jobs.api';
import { useEffect, useState } from 'react';
import { ApiResponseStatus } from '../../common/http/http.model';
import { JobsByCategory } from '../../api/jobs/jobs.model';
import { routesPaths } from '../../config/routes';
import screenRelativeTo1440 from '../../components/styled/utility/screenRelativeTo1440';
import media from 'styled-media-query';
import useIsMobileView from '../../common/hooks/useIsMobileView';

const Container = styled.section`
    ${media.lessThan('medium')`
    padding-right: 20px;
    `}
`;

const ProductAndTechCategory = styled(EntityList)`
    --category__accent: var(--secondary__default);
    --category__background: white;
    --category__text-color: var(--grey__dark);
    --category__question-color--active: var(--grey__dark);
    --category__answer-color: var(--grey__dark);
    --category__circle-color: var(--secondary__default);
    padding: 80px ${screenRelativeTo1440('140px')};

    ${media.lessThan('medium')`
    padding: 20px 20px 20px 20px;
    font-size: 18px;
    `}
`;

const BusinessAndSalesCategory = styled(ProductAndTechCategory)`
    background: var(--secondary__thin__30);
`;

const FinanceAndSupport = styled(ProductAndTechCategory)``;

const Jobs = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const jobsApi = useJobsApi();
    const [jobCategories, setJobCategories] = useState<JobsByCategory>();
    const isMobileView = useIsMobileView();

    useEffect(() => {
        (async () => {
            const response = await jobsApi.all();
            if (
                response.status === ApiResponseStatus.Success &&
                response.result
            ) {
                setJobCategories(
                    Object.keys(response.result).reduce(
                        (jobCategories: JobsByCategory, category) => ({
                            ...jobCategories,
                            [category]: jobCategories[category].map((job) => ({
                                ...job,
                                action: {
                                    text: t('careers.jobs.button_text'),
                                    onClick: () => {
                                        navigate(
                                            routesPaths.applyJob.replace(
                                                ':id',
                                                job.id
                                            ),
                                            {
                                                state: JSON.stringify(job),
                                            }
                                        );
                                    },
                                },
                            })),
                        }),
                        response.result
                    )
                );
            }
        })();
    }, []);

    return (
        <Container>
            {jobCategories && (
                <>
                    <BusinessAndSalesCategory
                        entities={jobCategories.business_and_sales}
                        iconName={!isMobileView ? 'plus--dark' : undefined}
                        title={t('careers.jobs.titles.business_and_sales')}
                        border
                    />
                    <ProductAndTechCategory
                        entities={jobCategories.product_and_tech}
                        iconName={!isMobileView ? 'plus--dark' : undefined}
                        title={t('careers.jobs.titles.product_and_tech')}
                        border
                    />
                    <FinanceAndSupport
                        entities={jobCategories.finance_and_support}
                        iconName={!isMobileView ? 'plus--dark' : undefined}
                        title={t('careers.jobs.titles.finance_and_support')}
                        border
                    />
                </>
            )}
        </Container>
    );
};

export default Jobs;
