import React from 'react';
import styled, { StyledComponent } from 'styled-components';
import BaseButton from '../styled/Button';
import screenRelativeTo1440 from '../styled/utility/screenRelativeTo1440';
import Icon from '../svg-icon/Icon';

const Container = styled.section`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 120px ${screenRelativeTo1440('120px')};
    row-gap: 48px;
`;

const Circle = styled(Icon).attrs(() => ({
    size: '520px',
    name: 'red-circle',
}))`
    position: absolute;
    opacity: 0.7;
    left: ${screenRelativeTo1440('270px')};
    top: 0;
    filter: blur(200px);
    z-index: -1;
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    row-gap: 8px;
    margin-right: 140px;
`;

const Button = styled(BaseButton)`
    align-self: flex-end;
    background: var(--alternative__medium);
`;

const Message = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 200px;
`;

interface TextComponent {
    text: string;
    Component: StyledComponent<any, any>;
}

interface ButtonDefinition extends TextComponent {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface ActionCallProps {
    messages: {
        first: TextComponent;
        second?: TextComponent;
    };
    buttons: ButtonDefinition[];
    className?: string;
    buttonClassName?: string;
    onActionClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const DeveloperApiView = ({
    messages,
    buttons,
    className,
    buttonClassName,
}: ActionCallProps) => {
    const FirstMessage = messages.first.Component;
    const SecondMessage = messages.second?.Component;

    return (
        <Container className={className}>
            <Circle />
            <Message>
                <FirstMessage>{messages.first.text}</FirstMessage>
                {SecondMessage && (
                    <SecondMessage>{messages.second?.text}</SecondMessage>
                )}
            </Message>
            <ButtonsContainer>
                {buttons.map(({ text, Component, onClick }, index) => (
                    <Button
                        key={index}
                        className={buttonClassName}
                        onClick={onClick}
                    >
                        <Component additional={{ color: 'white' }}>
                            {text}
                        </Component>
                    </Button>
                ))}
            </ButtonsContainer>
        </Container>
    );
};

export default DeveloperApiView;
