import { css } from 'styled-components';
import { AdditionalRules } from '../../../types/common';

export interface UnderlinedTextStyleProps {
    underline: {
        color: string;
        width?: string;
        height?: string;
        bottom?: string;
    };
    underlineAdditional?: AdditionalRules['additional'];
}

const UnderlinedTextStyle = css<UnderlinedTextStyleProps & AdditionalRules>`
    position: relative;

    &:after {
        position: absolute;
        content: 'text';
        color: transparent;
        left: 0;
        bottom: ${({ underline }) => underline.bottom || '-18px'};
        height: ${({ underline }) => underline.height || '10px'};
        width: ${({ underline }) => underline.width || '50%'};
        background: ${({ underline }) => underline.color};

        ${({ additional, underlineAdditional }) =>
            underlineAdditional || additional}
    }
`;

export default UnderlinedTextStyle;
