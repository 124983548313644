import styled, { css } from 'styled-components';
import {
    SubtitleRegular,
    Title1Bold,
    Title2Bold,
} from '../../components/styled/typography';
import UnderlinedTextStyle from '../../components/styled/css/UnderlinedTextStyle';
import { useTranslation } from 'react-i18next';
import { Card as BaseCard, CardHeader } from 'components/styled/Card';
import screenRelativeTo1440 from '../../components/styled/utility/screenRelativeTo1440';
import media from 'styled-media-query';
import useIsMobileView from '../../common/hooks/useIsMobileView';

const EvenCardStyle = css`
    margin-right: auto;
`;

const OddCardStyle = css`
    transform: translateY(50%);
`;

const Container = styled.section`
    display: flex;
    flex-direction: column;
    padding: 96px ${screenRelativeTo1440('130px')};

    ${media.lessThan('medium')`
    margin-top: 48px;
    padding: 0 0 0 0;
    `}
`;

const Title = styled(Title1Bold)`
    margin: 0 auto;
    ${UnderlinedTextStyle}

    ${media.lessThan('medium')`
    font-size: 18px;
    `}
`;

const ValueList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 170px;

    ${media.lessThan('medium')`
    flex-direction: column;
    align-items: center;;
    margin-top: 20px;
    margin-bottom: 50px;
    `}
`;

const Card = styled(BaseCard)<{ even: boolean }>`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 18px 50px 34px 12px;
    margin-bottom: 96px;
    width: 550px;
    height: unset;
    min-height: 240px;
    box-shadow: none;
    ${({ even }) => (even ? EvenCardStyle : OddCardStyle)}
`;

const CardHeaderText = styled(Title2Bold)`
    ${media.lessThan('medium')`
    font-size: 16px;
    `}
`;

const CardBody = styled(SubtitleRegular)`
    margin: 24px 0 0 100px;
    width: 390px;

    ${media.lessThan('medium')`
    width: 100%;
    margin: unset;
    padding-top: 24px;
    font-size: 14px;
    `}
`;

const CardMobileView = styled(BaseCard)<{ even: boolean }>`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 2px 50px 34px 12px;
    margin-top: 30px;
    width: 80%;
    height: unset;
    min-height: 240px;
    border-bottom-right-radius: 50px;
    box-shadow: 0 2px 6px 0 #00000026;
`;

const Values = () => {
    const { t } = useTranslation();
    const isMobileView = useIsMobileView();
    const CardComponent = isMobileView ? CardMobileView : Card;

    return (
        <Container>
            <Title
                underline={{
                    color: 'var(--alternative__medium)',
                    width: '66px',
                    bottom: isMobileView ? '6px' : undefined,
                    height: isMobileView ? '6px' : '10px',
                }}
            >
                {t('about_us.values.title')}
            </Title>
            <ValueList>
                {new Array(6).fill(0).map((_, index) => (
                    <CardComponent key={index} even={index % 2 === 0}>
                        <CardHeader
                            circle={{
                                size: isMobileView ? 56 : 100,
                                top: isMobileView ? -10 : -42,
                                left: -20,
                                color: 'var(--alternative__thin)',
                            }}
                            isSvg={false}
                        >
                            <CardHeaderText>
                                {t(`about_us.values.cards.${index}.title`)}
                            </CardHeaderText>
                        </CardHeader>
                        <CardBody>
                            {t(`about_us.values.cards.${index}.body`)}
                        </CardBody>
                    </CardComponent>
                ))}
            </ValueList>
        </Container>
    );
};

export default Values;
