import { Links } from 'common/constants/links';
import DeveloperApiMobileView from 'components/action-call/DeveloperApiMobileView';
import DeveloperApiView from 'components/action-call/DeveloperApiView';
import { useTranslation } from 'react-i18next';
import useIsMobileView from '../../common/hooks/useIsMobileView';
import ActionCall from '../../components/action-call/ActionCall';
import ActionCallMobileView from '../../components/action-call/ActionCallMobileView';
import {
    Body1Medium,
    H3Medium,
    H3Regular,
    Title2Medium,
    Title2Regular,
} from '../../components/styled/typography';
import config from '../../config';
import Features from './Features';
import Numbers from './Numbers';
import SignUpCall from './SignUpCall';

function sendEmail(address: string) {
    window.location.href = 'mailto:' + address;
}

function openDocument(path: string) {
    window.open(`${config().cdnUrl}/common/documents/${path}`);
}

const ForBusiness = () => {
    const { t } = useTranslation();
    const isMobileView = useIsMobileView();
    const ActionCallComponent = isMobileView
        ? ActionCallMobileView
        : ActionCall;

    const DeveloperApiViewComponent = isMobileView
        ? DeveloperApiMobileView
        : DeveloperApiView;

    const guides = [
        {
            label: t('for_business.developer_api.button.onlineApiGuideline'),
            onClick: () => openDocument('Online_API_Guide.pdf'),
        },
        {
            label: t('for_business.developer_api.button.inStoreApiGuideline'),
            onClick: () => openDocument('InStore_API_Guide.pdf'),
        },
        {
            label: t('for_business.developer_api.button.magentoIntegration'),
            onClick: () => openDocument('Magento_Guide.pdf'),
        },
        {
            label: t('for_business.developer_api.button.widgetIntegration'),
            onClick: () => openDocument('Widget_Guide.pdf'),
        },
        {
            label: t('for_business.developer_api.button.iosSDK'),
            onClick: () => window.open(Links.IOS_SDK, '_blank'),
        },
        {
            label: t('for_business.developer_api.button.androidSDK'),
            onClick: () => window.open(Links.ANDROID_SDK, '_blank'),
        },
    ];

    return (
        <>
            <SignUpCall />
            <Numbers />
            <Features />
            <DeveloperApiViewComponent
                messages={{
                    first: {
                        text: t('for_business.developer_api.first'),
                        Component: isMobileView ? Title2Regular : H3Regular,
                    },
                    second: {
                        text: t('for_business.developer_api.second'),
                        Component: isMobileView ? Title2Medium : H3Medium,
                    },
                }}
                buttons={guides.map((guide) => ({
                    text: guide.label,
                    onClick: guide.onClick,
                    Component: Body1Medium,
                }))}
            />
            <ActionCallComponent
                messages={{
                    first: {
                        text: t('for_business.contact_us.messages.first'),
                        component: isMobileView ? Title2Regular : H3Regular,
                    },
                    second: {
                        text: t('for_business.contact_us.messages.second'),
                        component: isMobileView ? Title2Medium : H3Medium,
                    },
                }}
                button={{
                    text: t('for_business.contact_us.button'),
                    component: Body1Medium,
                }}
                onActionClick={() => sendEmail(t('footer.contact.e-mail'))}
            />
        </>
    );
};

export default ForBusiness;
