import styled from 'styled-components';

const DomCircle = styled.div<{
    size: string;
    color: string;
    top?: string;
    left?: string;
}>`
    position: absolute;
    z-index: 0;
    border-radius: 100%;
    background: ${({ color }) => color};
    height: ${({ size }) => size};
    width: ${({ size }) => size};
    top: ${({ top }) => top || '0'};
    left: ${({ left }) => left || '0'};
`;

export default DomCircle;
