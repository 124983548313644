import { Body1Regular, SubtitleMedium } from 'components/styled/typography';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Icon from '../../components/svg-icon/Icon';
import { IconType } from '../../components/svg-icon/icon-types';
import screenRelativeTo1440 from '../../components/styled/utility/screenRelativeTo1440';
import media from 'styled-media-query';

const Container = styled.section`
    display: flex;
    justify-content: space-between;
    padding: 72px ${screenRelativeTo1440('120px')};

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    flex-direction: column;
    align-items: center;
    `}
`;

const Feature = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 24px 40px 24px 24px;
    box-shadow: 0 2px 6px 0 #00000026;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;
    width: 390px;
    box-sizing: border-box;

    &:before {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        content: 'background';
        color: transparent;
        background: var(--grey__x-thin);
        opacity: 0.5;
        z-index: -1;
    }

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    width: 90%;
    margin-top: 15px;
    border-bottom-right-radius: 30px;

    `}
`;

const FeatureHeader = styled.div`
    position: relative;
    padding-left: 72px;
    height: 56px;
    display: flex;
    align-items: center;
`;

const FeatureIcon = styled(Icon).attrs(() => ({
    size: '32px',
}))`
    position: absolute;
    top: 5px;
    left: 7px;
    z-index: 10;
`;

const BlueCircle = styled(Icon).attrs(() => ({
    size: '56px',
    name: 'blue-circle',
}))`
    position: absolute;
    top: 0;
    left: 0;
`;

const Features = () => {
    const { t } = useTranslation();

    return (
        <Container>
            {(['shop', 'split', 'manage'] as IconType[]).map(
                (category: IconType) => (
                    <Feature key={category}>
                        <FeatureHeader>
                            <FeatureIcon name={category} />
                            <BlueCircle />
                            <SubtitleMedium>
                                {t(`home_page.features.${category}.title`)}
                            </SubtitleMedium>
                        </FeatureHeader>
                        <Body1Regular
                            additional={{
                                marginTop: '16px',
                            }}
                        >
                            {t(`home_page.features.${category}.body`)}
                        </Body1Regular>
                        <SubtitleMedium
                            additional={{
                                marginTop: '16px',
                            }}
                        >
                            {t(`home_page.features.${category}.punch-line`)}
                        </SubtitleMedium>
                    </Feature>
                )
            )}
        </Container>
    );
};

export default Features;
