export enum HttpResponseErrorKeys {}

export enum ApiResponseStatus {
    Success = 'success',
    Error = 'error',
}

export interface BaseResponse<T> {
    result: T;
    status: ApiResponseStatus;
    messages?: HttpResponseErrorKeys;
}

export interface BasePaginationResponse<T> {
    result: Pagination<T>;
    status: ApiResponseStatus;
    messages?: HttpResponseErrorKeys;
}

export type HttpResponse<T> = Promise<BaseResponse<T>>;
export type HttpPaginationResponse<T> = Promise<BasePaginationResponse<T>>;

export interface PaginationOptions {
    total: number;
    totalPages: number;
    selectedPage: number;
    limit: number;
}

export interface PaginationQuery {
    page?: number;
    limit?: number;
}
export interface Pagination<T> {
    rows: T[];
    pagination: PaginationOptions;
}
