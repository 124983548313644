import config from 'config';
import { routesPaths } from 'config/routes';
import { useTranslation } from 'react-i18next';
import useIsMobileView from '../../../../common/hooks/useIsMobileView';
import * as S from './Footer.styles';
import FooterLinkGroup from './FooterLinkGroup';
import { FooterLinkGroupItem } from './types';
import { Links } from 'common/constants/links';

function createDocumentLink(path: string) {
    return `${config().cdnUrl}/common/documents/${path}`;
}

const useFooterLinkGroupsContent = (): FooterLinkGroupItem[] => {
    const { t } = useTranslation();

    return [
        {
            title: t('footer.links.mis_pay'),
            links: [
                {
                    href: routesPaths.aboutUs,
                    label: t('footer.links.about_us'),
                },
                {
                    href: routesPaths.shariyaCompliance,
                    label: t('footer.links.shariya_compliance'),
                },
            ],
        },
        {
            title: t('footer.links.shop'),
            links: [
                {
                    href: routesPaths.howItWorks,
                    label: t('footer.links.how_it_works'),
                },
            ],
        },
        {
            title: t('footer.links.for_business'),
            links: [
                {
                    href: Links.BECOME_MERCHANT,
                    label: t('footer.links.become_merchant'),
                    external: true,
                },
                {
                    href: config().merchantDashboardUrl,
                    label: t('footer.links.merchant_login'),
                    external: true,
                },
                {
                    href: createDocumentLink('Online_API_Guide.pdf'),
                    label: t('footer.links.onlineApiGuideline'),
                    external: true,
                },
                {
                    href: createDocumentLink('InStore_API_Guide.pdf'),
                    label: t('footer.links.inStoreApiGuideline'),
                    external: true,
                },
                {
                    href: createDocumentLink('Magento_Guide.pdf'),
                    label: t('footer.links.magentoIntegration'),
                    external: true,
                },
                {
                    href: createDocumentLink('Widget_Guide.pdf'),
                    label: t('footer.links.widgetIntegration'),
                    external: true,
                },
                {
                    href: Links.IOS_SDK,
                    label: t('footer.links.iosSDK'),
                    external: true,
                },
                {
                    href: Links.ANDROID_SDK,
                    label: t('footer.links.androidSDK'),
                    external: true,
                },
            ],
        },
        {
            title: t('footer.links.questions'),
            links: [
                {
                    href: routesPaths.frequentlyAskedQuestions,
                    label: t('footer.links.help_center'),
                },
            ],
        },
    ];
};

const MobileFooter = () => {
    const { t } = useTranslation();
    const linkGroupsContent = useFooterLinkGroupsContent();

    return (
        <S.ContainerMobileView>
            <S.StyledSvgIcon name="logo--light" height="50px" width="112px" />
            <S.PunchLine>{t('footer.punch_line')}</S.PunchLine>
            <S.FooterLinkGroupsContainer>
                {linkGroupsContent.map((group, index) => (
                    <FooterLinkGroup {...group} key={index} />
                ))}
            </S.FooterLinkGroupsContainer>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <S.StoreLinks>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.mispaymobile"
                        target="_blank"
                    >
                        <S.StoreIcon
                            name="play-store"
                            width="140px"
                            height="40px"
                            margin
                        />
                    </a>
                    <a
                        href="https://apps.apple.com/gb/app/mispay/id6443602765"
                        target="_blank"
                    >
                        <S.StoreIcon
                            name="app-store"
                            width="140px"
                            height="40px"
                        />
                    </a>
                </S.StoreLinks>
            </div>
            <S.Header marginTop="72px" style={{ textAlign: 'center' }}>
                {t('footer.contact.header')}
            </S.Header>
            <S.ContactLink
                style={{ textAlign: 'center' }}
                href={'mailto:' + t('footer.contact.e-mail')}
            >
                {t('footer.contact.e-mail')}
            </S.ContactLink>
            <S.AddressLine marginTop="16px" style={{ textAlign: 'center' }}>
                MIS Pay <br /> Regulated by the Saudi Central Bank <br />
                C.R. 1010764928
            </S.AddressLine>
            <S.AddressLine marginTop="16px" style={{ textAlign: 'center' }}>
                {t('footer.contact.address.first')}
            </S.AddressLine>
            <S.AddressLine style={{ textAlign: 'center' }}>
                {t('footer.contact.address.second')}
            </S.AddressLine>
            <S.SocialIcons>
                <S.SocialLink
                    href={t('footer.contact.socials.facebook')}
                    target="_blank"
                >
                    <S.SocialSvgIcon name="facebook" size="32px" />
                </S.SocialLink>
                <S.SocialLink
                    href={t('footer.contact.socials.twitter')}
                    target="_blank"
                >
                    <S.SocialSvgIcon name="twitter" size="32px" />
                </S.SocialLink>
                <S.SocialLink
                    href={t('footer.contact.socials.tiktok')}
                    target="_blank"
                >
                    <S.SocialSvgIcon name="tiktok" size="32px" />
                </S.SocialLink>
                <S.SocialLink
                    href={t('footer.contact.socials.instagram')}
                    target="_blank"
                >
                    <S.SocialSvgIcon name="instagram" size="32px" />
                </S.SocialLink>
            </S.SocialIcons>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <S.LicenseRow>
                    <S.LicenseText marginRight="16px">
                        {t('footer.license.year')}
                    </S.LicenseText>
                    <S.LicenseText>{t('footer.license.rights')}</S.LicenseText>
                </S.LicenseRow>
                <S.LawRow>
                    <S.LicenseLink
                        marginRight="16px"
                        to={routesPaths.privacyPolicy}
                    >
                        {t('footer.law.privacy')}
                    </S.LicenseLink>
                    <S.LicenseLink to={routesPaths.termsAndConditions}>
                        {t('footer.law.terms')}
                    </S.LicenseLink>
                </S.LawRow>
            </div>
        </S.ContainerMobileView>
    );
};

const Footer = () => {
    const { t } = useTranslation();
    const isMobileView = useIsMobileView();
    const linkGroupsContent = useFooterLinkGroupsContent();

    return (
        <S.Container>
            {isMobileView ? (
                <MobileFooter />
            ) : (
                <>
                    <S.LeftColumn>
                        <S.StyledSvgIcon
                            name="logo--light"
                            height="50px"
                            width="112px"
                        />
                        <S.PunchLine>{t('footer.punch_line')}</S.PunchLine>
                        <S.FooterLinkGroupsContainer>
                            {linkGroupsContent.map((group, index) => (
                                <FooterLinkGroup {...group} key={index} />
                            ))}
                        </S.FooterLinkGroupsContainer>
                        <S.StoreLinks>
                            <a
                                target="_blank"
                                href="https://play.google.com/store/apps/details?id=com.mispaymobile"
                            >
                                <S.StoreIcon
                                    name="play-store"
                                    width="140px"
                                    height="40px"
                                    margin
                                />
                            </a>
                            <a
                                target="_blank"
                                href="https://apps.apple.com/gb/app/mispay/id6443602765"
                            >
                                <S.StoreIcon
                                    name="app-store"
                                    width="140px"
                                    height="40px"
                                />
                            </a>
                        </S.StoreLinks>
                        <S.LicenseRow>
                            <S.LicenseText marginRight="16px">
                                {t('footer.license.year')}
                            </S.LicenseText>
                            <S.LicenseText>
                                {t('footer.license.rights')}
                            </S.LicenseText>
                        </S.LicenseRow>
                    </S.LeftColumn>
                    <S.RightColumn>
                        <S.SocialIcons>
                            <S.SocialLink
                                href={t('footer.contact.socials.facebook')}
                                target="_blank"
                            >
                                <S.SocialSvgIcon name="facebook" size="32px" />
                            </S.SocialLink>
                            <S.SocialLink
                                href={t('footer.contact.socials.twitter')}
                                target="_blank"
                            >
                                <S.SocialSvgIcon name="twitter" size="32px" />
                            </S.SocialLink>
                            <S.SocialLink
                                href={t('footer.contact.socials.tiktok')}
                                target="_blank"
                            >
                                <S.SocialSvgIcon name="tiktok" size="32px" />
                            </S.SocialLink>
                            <S.SocialLink
                                href={t('footer.contact.socials.instagram')}
                                target="_blank"
                            >
                                <S.SocialSvgIcon name="instagram" size="32px" />
                            </S.SocialLink>
                        </S.SocialIcons>
                        <S.Header marginTop="72px">
                            {t('footer.contact.header')}
                        </S.Header>
                        <S.AddressLine marginTop="16px">
                            MIS Pay <br /> Regulated by the Saudi Central Bank{' '}
                            <br />
                            C.R. 1010764928
                        </S.AddressLine>
                        <S.ContactLink
                            href={'mailto:' + t('footer.contact.e-mail')}
                        >
                            {t('footer.contact.e-mail')}
                        </S.ContactLink>
                        <S.AddressLine marginTop="16px">
                            {t('footer.contact.address.first')}
                        </S.AddressLine>
                        <S.AddressLine>
                            {t('footer.contact.address.second')}
                        </S.AddressLine>
                        <S.LawRow>
                            <S.LicenseLink
                                marginRight="16px"
                                to={routesPaths.privacyPolicy}
                            >
                                {t('footer.law.privacy')}
                            </S.LicenseLink>
                            <S.LicenseLink to={routesPaths.termsAndConditions}>
                                {t('footer.law.terms')}
                            </S.LicenseLink>
                        </S.LawRow>
                    </S.RightColumn>
                </>
            )}
        </S.Container>
    );
};

export default Footer;
