import { mediaQuery } from '@jouri/components';
import screenRelativeTo1440 from 'components/styled/utility/screenRelativeTo1440';
import Icon from 'components/svg-icon/Icon';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const TextStyles = css`
    opacity: 0.8;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.190099px;
`;

export const LinkStyles = css`
    text-decoration: none;
    color: white;

    &:hover {
        text-decoration: underline;
    }
`;

export const Container = styled.footer`
    background: var(--grey__dark);
    color: white;
    padding: 40px ${screenRelativeTo1440('120px')};
    display: flex;
`;

export const ContainerMobileView = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 24px 30px;
    width: 100%;
`;

export const StyledSvgIcon = styled(Icon)`
    flex-basis: 100%;
    margin-bottom: 20px;

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    margin-bottom: 10px;
    `}
`;

export const SocialLink = styled.a`
    display: inline-block;
    margin-left: 8px;
`;

export const SocialSvgIcon = styled(Icon)`
    &:not(:last-child) {
        margin-right: 16px;
    }
`;

export const Text = styled.span`
    ${TextStyles}
`;

export const PunchLine = styled(Text)`
    flex-basis: 100%;

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    margin-top: -10px;
    z-index: 1;
    `}
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LeftColumn = styled(Column)`
    justify-content: flex-start;
    flex-basis: 60%;
`;

export const RightColumn = styled(Column)`
    align-items: flex-end;
    flex-basis: 40%;
    padding-top: 90px;
`;

export const FooterLinkGroupsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    column-gap: 72px;
    row-gap: 48px;

    ${mediaQuery.lessThan('tablet')`
        flex-wrap: wrap;
    `}
`;

export const Header = styled.span<{
    marginTop?: string;
    width?: string;
    marginBottom?: string;
}>`
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.19px;
    font-weight: 700;
    margin-top: ${({ marginTop }) => marginTop || 0};
    margin-bottom: ${({ marginBottom }) => marginBottom || 0};
    width: ${({ width }) => width || 'unset'};

    ${media.lessThan('medium')`
    white-space: nowrap;
    `}
`;

export const StyledLink = styled(Link)<{ bold?: boolean }>`
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.19px;
    font-weight: ${({ bold }) => (bold ? '700' : '400')};
    width: 25%;
    ${LinkStyles}

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    width: unset;
    margin-bottom: 10px;
    white-space: nowrap;
    `}
`;

export const StyledExternalLink = styled.a<{ bold?: boolean }>`
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.19px;
    font-weight: ${({ bold }) => (bold ? '700' : '400')};
    width: 25%;
    ${LinkStyles}

    ${media.lessThan('medium')`
    width: unset;
    margin-bottom: 10px;
    `}
`;

export const StoreLinks = styled.div`
    margin-top: 60px;
    z-index: 999;
`;

export const StoreIcon = styled(Icon)<{ margin?: boolean }>`
    margin-right: ${({ margin }) => (margin ? '16px' : '0')};
`;

export const LicenseRow = styled.div`
    margin-top: 65px;
    display: flex;

    ${media.lessThan('medium')`
    flex-direction: column;
    `}
`;

export const LicenseText = styled.span<{ marginRight?: string }>`
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.19px;
    color: white;
    margin-right: ${({ marginRight }) => marginRight || 0};

    ${media.lessThan('medium')`
    margin-bottom: 10px;
    `}
`;

export const LicenseLink = styled(Link)<{ marginRight?: string }>`
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.19px;
    color: white;
    margin-right: ${({ marginRight }) => marginRight || 0};

    ${media.lessThan('medium')`
    margin-bottom: 10px;
    `}
`;

export const SocialIcons = styled.div`
    display: flex;
    justify-content: flex-end;

    ${media.lessThan('medium')`
    justify-content: center;
    margin-top: 30px;
    `}
`;

export const ContactLink = styled.a`
    ${TextStyles}
    ${LinkStyles}
  margin-top: 16px;
`;

export const AddressLine = styled(Text)<{ marginTop?: string }>`
    margin-top: ${({ marginTop }) => marginTop || 0};
    text-align: right;
`;

export const LawRow = styled.div`
    display: flex;
    margin-top: auto;

    ${media.lessThan('medium')`
    flex-direction: column;
    `}
`;

export const HalfWidthColumn = styled.div`
    display: flex;
    margin-top: 30px;
    margin-left: 15px;
    width: 50%;
    flex-direction: column;
`;

export const MobileLinksContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
