import { AppRoute } from 'components/app-routes';
import BaseLayout from 'components/layouts/base-layout';
import Home from 'pages/home';
import HowItWorks from 'pages/how-it-works';
// import Shop from 'pages/shop';
import ForBusiness from '../pages/for-business';
import AboutUs from '../pages/about-us';
import FrequentlyAskedQuestions from '../pages/frequently-asked-questions';
import Careers from '../pages/careers';
import JobApplication from '../pages/job-application';
import ShariyaCompliance from '../pages/shariya-compliance';
import Policy from '../pages/policy';
import TermsAndConditions from '../pages/terms-and-conditions';
import ErrorPage from 'pages/error-page';

export const routesPaths = {
    root: '/',
    howItWorks: '/how-it-works',
    // shop: '/shop',
    forBusiness: '/for-business',
    frequentlyAskedQuestions: '/questions',
    aboutUs: '/about-us',
    partners: '/partners',
    helpCenter: '/help-center',
    careers: '/careers',
    applyJob: '/jobs/:id',
    merchantLogin: '/merchant-login',
    shariyaCompliance: '/shariya-compliance',
    privacyPolicy: '/privacy-policy',
    termsAndConditions: '/terms-and-conditions',
    wild: '*',
};

export const appRoutes: AppRoute[] = [
    {
        exact: true,
        path: routesPaths.root,
        layout: BaseLayout,
        component: Home,
    },
    {
        exact: true,
        path: routesPaths.howItWorks,
        component: HowItWorks,
    },
    // {
    //     exact: true,
    //     path: routesPaths.shop,
    //     layout: BaseLayout,
    //     component: Shop,
    // },
    {
        exact: true,
        path: routesPaths.forBusiness,
        layout: BaseLayout,
        component: ForBusiness,
    },
    {
        exact: true,
        path: routesPaths.aboutUs,
        component: AboutUs,
    },
    {
        exact: true,
        path: routesPaths.frequentlyAskedQuestions,
        component: FrequentlyAskedQuestions,
    },
    {
        exact: true,
        path: routesPaths.careers,
        layout: BaseLayout,
        component: Careers,
    },
    {
        exact: true,
        path: routesPaths.applyJob,
        layout: BaseLayout,
        component: JobApplication,
    },
    {
        exact: true,
        path: routesPaths.shariyaCompliance,
        component: ShariyaCompliance,
    },
    {
        exact: true,
        path: routesPaths.privacyPolicy,
        component: Policy,
    },
    {
        exact: true,
        path: routesPaths.termsAndConditions,
        component: TermsAndConditions,
    },
    {
        exact: true,
        path: routesPaths.wild,
        component: ErrorPage,
        redirect: '404',
    },
];
