import styled from 'styled-components';
import TextWithCircle from '../text-with-circle/TextWithCircle';
import { Title2Bold } from '../styled/typography';
import { IconType } from '../svg-icon/icon-types';
import React from 'react';
import Details, { Entity } from '../entity/Details';
import screenRelativeTo1440 from '../styled/utility/screenRelativeTo1440';
import media from 'styled-media-query';
import useIsMobileView from '../../common/hooks/useIsMobileView';

const Container = styled.div`
    background: var(--category__background);
    padding: 0 ${screenRelativeTo1440('140px')};

    ${media.lessThan('medium')`
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 30px;
    margin-bottom: 50px;
    `}
`;

const EntityContainer = styled.div``;
const TitleContainer = styled.div`
    ${media.lessThan('medium')`
     width: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    `}
`;

const Title = styled(TextWithCircle).attrs(
    ({ $color }: { $color: string }) => ({
        circle: {
            size: 40,
            color: $color,
            top: 5,
            left: -20,
        },
        isSvg: false,
    })
)<{ $color: string }>`
    margin-bottom: 130px;

    ${media.lessThan('medium')`
    margin: 0 auto 50px auto;
    `}
`;

const TitleText = styled(Title2Bold)`
    color: var(--category__text-color);

    ${media.lessThan('medium')`
    font-size: 24px;
    `}
`;

const Divider = styled.div`
    margin: 32px 0;
    width: 100%;
    border-top: 1px solid var(--grey__thin);

    ${media.lessThan('medium')`
    margin: 20px 0;
    `}
`;

interface CategoryProps {
    iconName?: IconType;
    entities: Entity[];
    title: string;
    border?: boolean;
    className?: string;
}

const EntityList = ({
    entities,
    title,
    iconName,
    border,
    className,
}: CategoryProps) => {
    const isMobileView = useIsMobileView();
    return (
        <Container className={className}>
            <TitleContainer>
                <Title
                    $color={`${
                        isMobileView
                            ? 'transparent'
                            : 'var(--category__circle-color)'
                    }`}
                >
                    <TitleText>{title}</TitleText>
                </Title>
            </TitleContainer>
            {entities.map((entity, index) => (
                <EntityContainer key={title + entity.title}>
                    <Details
                        key={title + entity.title + index}
                        entity={entity}
                        iconName={iconName}
                        border={border}
                    />
                    {index + 1 < entities.length && (
                        <Divider
                            key={title + entity.title + index + 'divider'}
                        />
                    )}
                </EntityContainer>
            ))}
        </Container>
    );
};

export default EntityList;
