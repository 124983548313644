import TextWithCircle from 'components/text-with-circle/TextWithCircle';
import styled from 'styled-components';
import { AdditionalRules } from 'types/common';
import { Body1Regular, Title3Bold } from './typography';
import media from 'styled-media-query';

export const Card = styled.div<
    { height?: string; width?: string } & AdditionalRules
>`
    display: flex;
    flex-direction: column;
    background: var(--grey__x-thin);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    padding: 20px 40px 40px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: ${({ width }) => width || '390px'};
    min-height: ${({ height }) => height || '200px'};
    box-sizing: border-box;
    ${({ additional }) => additional}
    ${media.lessThan('small')`
        padding: 10px 20px 20px;
    `}
`;

export const CardHeader = styled(TextWithCircle).attrs(({ circle }) => ({
    circle: {
        size: circle?.size || 56,
        left: circle?.left || -28,
        top: circle?.top || -16,
        color: circle?.color || 'blue',
    },
}))<AdditionalRules>`
    position: relative;
    ${({ additional }) => additional}
`;

export const CardTitle = styled(Title3Bold)`
    color: var(--grey__medium);
    ${media.lessThan('small')`
        font-size: 20px;
        line-height: 22px;
    `}
`;

export const CardBody = styled(Body1Regular)<AdditionalRules>`
    margin-top: 24px;
    ${({ additional }) => additional}
`;
