import useShouldBypassAuth from 'common/hooks/useShouldBypassAuth';
import * as CryptoJS from 'crypto-js';
import useAuthApi from 'jouri-oauth/auth/auth.api';
import qs from 'query-string';
import { createContext, useCallback, useContext, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { OAUTH_PARAMS } from './oauth.const';

interface JouriOAuthConfig {
    clientId: string;
    returnUrl?: string;
}

interface JouriOAuthProviderProps extends JouriOAuthConfig {
    children: any;
}

interface JouriOAuthContextMethod {
    loginWithRedirect: () => void;
    logout: () => void;
}

interface JouriOAuthContextState extends JouriOAuthContextMethod {
    isLoading: boolean;
    isAuthenticated: boolean;
    token: string;
    config: JouriOAuthConfig;
}

const INITIAL_VALUE: any = {
    isLoading: true,
    isAuthenticated: false,
    config: { clientId: '' },
    token: '',
};

export const JouriOAuthContext = createContext<JouriOAuthContextState>(
    INITIAL_VALUE as JouriOAuthContextState
);

const JouriOAuthProvider = ({
    children,
    clientId,
    returnUrl,
}: JouriOAuthProviderProps) => {
    const [contextState, updateContextState] = useImmer<
        Omit<JouriOAuthContextState, 'login' | 'logout'>
    >({
        ...INITIAL_VALUE,
        config: { clientId, returnUrl },
    });

    const shouldBypassAuth = useShouldBypassAuth();

    const {
        loginWithRedirect: loginWithRedirectApi,
        postLogin: postLoginApi,
        token: tokenApi,
        logout: logoutApi,
    } = useAuthApi({ clientId: clientId });

    const init = useCallback(async () => {
        const params = qs.parse(window.location.search);

        if (params[OAUTH_PARAMS.OAUTH_POST]) {
            try {
                const response = await postLoginApi(
                    params[OAUTH_PARAMS.OAUTH_POST] as string
                );
                updateContextState((draft: any) => {
                    draft.isAuthenticated = true;
                    draft.token = response.result.token;
                    draft.isLoading = false;
                });
                history.replaceState(null, '', window.origin);
            } catch (error) {
                updateContextState((draft: any) => {
                    draft.isAuthenticated = false;
                    draft.isLoading = false;
                });
                history.replaceState(null, '', window.origin);
            }
        } else if (!shouldBypassAuth) {
            if (params[OAUTH_PARAMS.OAUTH_CODE]) {
                try {
                    const response = await tokenApi(
                        params[OAUTH_PARAMS.OAUTH_CODE] as string
                    );
                    updateContextState((draft: any) => {
                        draft.isAuthenticated = true;
                        draft.token = response.result.token;
                        draft.isLoading = false;
                    });

                    history.replaceState(null, '', window.origin);
                } catch (error) {
                    updateContextState((draft: any) => {
                        draft.isAuthenticated = false;
                        draft.isLoading = false;
                    });
                    history.replaceState(null, '', window.origin);
                }
            } else {
                try {
                    const response = await tokenApi();
                    updateContextState((draft: any) => {
                        draft.isAuthenticated = true;
                        draft.token = response.result.token;
                        draft.isLoading = false;
                    });
                } catch (error) {
                    updateContextState((draft: any) => {
                        draft.isAuthenticated = false;
                        draft.isLoading = false;
                    });
                }
            }
        }

        if (params['lang'] !== undefined)
            localStorage.setItem(
                '@preferredLanguage',
                params['lang'] as string
            );
    }, []);

    useEffect(() => {
        init();
    }, []);

    const loginWithRedirect = useCallback(async () => {
        const payload = {
            nonce: new Date().getTime(),
            returnUrl: window.origin,
        };

        const code = CryptoJS.AES.encrypt(
            JSON.stringify(payload),
            clientId
        ).toString();

        loginWithRedirectApi(code);
    }, [clientId]);

    const logout = useCallback(async (redirectToMarketingApp?: boolean) => {
        updateContextState((draft: any) => {
            draft.isLoading = true;
        });
        try {
            await logoutApi();

            if (!redirectToMarketingApp) {
                updateContextState((draft: any) => {
                    draft.isAuthenticated = false;
                    draft.token = '';
                    draft.isLoading = false;
                });
            }
        } catch (error) {
            updateContextState((draft: any) => {
                draft.isAuthenticated = false;
                draft.token = '';
            });
        }
    }, []);

    return (
        <JouriOAuthContext.Provider
            value={{ ...contextState, loginWithRedirect, logout }}
        >
            {children}
        </JouriOAuthContext.Provider>
    );
};

interface UseJouriOAuthReturn {
    isAuthenticated: boolean;
    isLoading: boolean;
    token: string;
    login: () => void;
    logout: (redirectToMarketingApp?: boolean) => void;
}

export const useJouriOAuth = (): UseJouriOAuthReturn => {
    const { isAuthenticated, isLoading, logout, token, loginWithRedirect } =
        useContext(JouriOAuthContext);

    return {
        login: loginWithRedirect,
        isAuthenticated,
        isLoading,
        token,
        logout,
    };
};

export default JouriOAuthProvider;
