import styled from 'styled-components';
import CenteredActionCall from '../../components/action-call/CenteredActionCall';
import { useTranslation } from 'react-i18next';
import BaseButton from '../../components/styled/Button';
import {
    Body1Medium,
    H3Medium,
    H3Regular,
    Title2Medium,
    Title2Regular,
} from 'components/styled/typography';
import screenRelativeTo1440 from '../../components/styled/utility/screenRelativeTo1440';
import useIsMobileView from '../../common/hooks/useIsMobileView';
import media from 'styled-media-query';
import BackgroundCircle from 'components/styled/BackgroundCircle';

const Container = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 70px 0 60px;
    background: var(--primary__thin__60);

    ${media.lessThan('medium')`
    padding: 68px 30px 76px 30px; 
    `}
`;

const ElevatedTitle2Regular = styled(Title2Regular)`
    z-index: 10;

    ${media.lessThan('medium')`
    font-size: 24px;
    `}
`;

const Button = styled(BaseButton)`
    margin-top: 44px;
    background: var(--secondary__medium);
    z-index: 10;
`;

const ButtonText = styled(Body1Medium)`
    color: white;
`;

const BackgroundRectangle = styled.div`
    position: absolute;
    width: 1300px;
    height: 200px;
    background: var(--alternative__thin);
    filter: blur(100px);
    opacity: 0.8;
    top: 150px;
    left: ${screenRelativeTo1440('60px')};

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    display: none;
    `}
`;

function sendEmail(address: string) {
    window.location.href = 'mailto:' + address;
}

const Title2RegularCenter = styled(Title2Regular)`
    text-align: center;
`;

const SignUpCall = () => {
    const { t } = useTranslation();
    const isMobileView = useIsMobileView();
    return (
        <Container>
            <CenteredActionCall
                firstLine={{
                    component: isMobileView ? Title2RegularCenter : H3Regular,
                    text: t('for_business.sign_up_call.title.0'),
                }}
                secondLine={{
                    component: isMobileView ? Title2Medium : H3Medium,
                    text: t('for_business.sign_up_call.title.1'),
                }}
                thirdLine={{
                    firstPart: {
                        component: ElevatedTitle2Regular,
                        text: t('for_business.sign_up_call.title.2'),
                    },
                }}
            />

            <Button
                onClick={() => {
                    sendEmail(t('footer.contact.e-mail'));
                }}
            >
                <ButtonText>{t('for_business.sign_up_call.button')}</ButtonText>
            </Button>
            <BackgroundRectangle />
            {!isMobileView && (
                <BackgroundCircle
                    size="310px"
                    color="var(--primary__light)"
                    additional={{
                        top: '50px',
                        right: screenRelativeTo1440('16px'),
                        filter: 'blur(100px)',
                    }}
                />
            )}
        </Container>
    );
};

export default SignUpCall;
