import useOptionalLayout from 'common/hooks/useOptionalLayout';
import BaseLayout from 'components/layouts/base-layout';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import media from 'styled-media-query';

import { H3Medium } from '../../components/styled/typography';
import screenRelativeTo1440 from '../../components/styled/utility/screenRelativeTo1440';
import config from '../../config';
import remarkGfm from 'remark-gfm';

const Title = styled(H3Medium)`
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary__light);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 64px;

    ${media.lessThan('medium')`
    margin-bottom: unset;
    font-size: 32px;
    `}
`;

const Body = styled.span`
    display: block;
    font-size: 18px;
    line-height: 22px;
    white-space: pre-line;
    padding: 64px ${screenRelativeTo1440('120px')} 220px;

    p {
        display: inline-block;
        margin-bottom: 24px;
    }

    strong {
        font-weight: bold;
    }

    ul {
        li {
            list-style-type: disc;
            margin-left: 48px;

            li {
                list-style-type: circle;
            }
        }
    }

    ol {
        li {
            list-style-type: decimal;

            li {
                margin-left: 48px;
            }
        }

        ul {
            li {
                list-style-type: disc;
            }
        }
    }

    td {
        border: 1px solid;
        padding: 6px;
    }

    th {
        border: 1px solid;
        padding: 6px;
    }

    ${media.lessThan('medium')`
    padding: 30px 10px 20px 10px;
    `}
`;

const Policy = () => {
    const { Layout } = useOptionalLayout(BaseLayout);
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const [tosText, setTosText] = useState('');

    const fetchData = useCallback(async () => {
        const response = await fetch(
            `${config().cdnUrl}/common/translations/${language}/policy.md`
        );
        const body = await response.text();
        setTosText(body);
    }, [language]);

    useEffect(() => {
        void fetchData();
    }, [language]);

    return (
        <Layout>
            <Title>{t(`policy.privacy_policy.title`)}</Title>
            <Body>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {tosText}
                </ReactMarkdown>
            </Body>
        </Layout>
    );
};

export default Policy;
