import useOptionalLayout from 'common/hooks/useOptionalLayout';
import BaseLayout from 'components/layouts/base-layout';
import AboutUsTitle from './AboutUsTitle';
import Teams from './Teams';
import Values from './Values';
import Vision from './Vision';

const AboutUs = () => {
    const { Layout, hideHrefs } = useOptionalLayout(BaseLayout, true);

    return (
        <Layout>
            <AboutUsTitle />
            <Vision />
            <Values />
            {!hideHrefs && <Teams />}
        </Layout>
    );
};

export default AboutUs;
