import { ReactNode } from 'react';
import styled from 'styled-components';
import Footer from './Footer';
import Navigation from './Navigation';

const Main = styled.main`
    flex-basis: 100%;
`;

interface BaseLayoutProps {
    children: ReactNode;
}

const BaseLayout = ({ children }: BaseLayoutProps) => {
    return (
        <>
            <Navigation />
            <Main>{children}</Main>
            <Footer />
        </>
    );
};

export default BaseLayout;
