import React from 'react';
import styled, { StyledComponent } from 'styled-components';
import BaseButton from '../styled/Button';
import Icon from '../svg-icon/Icon';

const Container = styled.section`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 0 20px 0 20px;
`;

const Circle = styled(Icon).attrs(() => ({
    size: '220px',
    name: 'red-circle',
}))`
    position: absolute;
    opacity: 0.7;
    top: 0;
    filter: blur(200px);
    z-index: -1;
`;

const Message = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Button = styled(BaseButton)`
    align-self: flex-end;
    margin-top: 48px;
    background: var(--alternative__medium);
`;

interface TextComponent {
    text: string;
    Component: StyledComponent<any, any>;
}

interface ButtonDefinition extends TextComponent {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface ActionCallProps {
    messages: {
        first: TextComponent;
        second?: TextComponent;
    };
    buttons: ButtonDefinition[];
    className?: string;
    buttonClassName?: string;
}

const DeveloperApiMobileView = ({
    messages,
    buttons,
    className,
    buttonClassName,
}: ActionCallProps) => {
    const FirstMessage = messages.first.Component;
    const SecondMessage = messages.second?.Component;

    return (
        <Container className={className}>
            <Circle />
            <Message>
                <FirstMessage>{messages.first.text}</FirstMessage>
                {SecondMessage && (
                    <SecondMessage>{messages.second?.text}</SecondMessage>
                )}
            </Message>
            {buttons.map(({ text, onClick, Component }, index) => (
                <Button
                    className={buttonClassName}
                    onClick={onClick}
                    key={index}
                >
                    <Component>{text}</Component>
                </Button>
            ))}
        </Container>
    );
};

export default DeveloperApiMobileView;
