import useOptionalLayout from 'common/hooks/useOptionalLayout';
import BaseLayout from 'components/layouts/base-layout';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import media from 'styled-media-query';

import { H3Medium } from '../../components/styled/typography';
import screenRelativeTo1440 from '../../components/styled/utility/screenRelativeTo1440';
import config from '../../config';
const Title = styled(H3Medium)`
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary__light);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 64px;

    ${media.lessThan('medium')`
    margin-bottom: unset;
    font-size: 32px;
    `}
`;

const Body = styled.span`
    display: block;
    font-size: 18px;
    line-height: 22px;
    white-space: pre-line;
    padding: 64px ${screenRelativeTo1440('120px')} 220px;

    h5,
    h6 {
        margin-top: 24px;
    }

    ol {
        counter-reset: item;
    }
    li {
        display: inline-block;
        position: relative;
    }

    p {
        display: inline-block;
        margin: 8px 0;
    }

    strong {
        font-weight: bold;
    }

    ${media.lessThan('medium')`
    padding: 30px 10px 20px 10px;
    `}
`;

const TermsAndConditions = () => {
    const { Layout } = useOptionalLayout(BaseLayout);

    const {
        t,
        i18n: { language },
    } = useTranslation();
    const [tosText, setTosText] = useState('');

    const fetchData = useCallback(async () => {
        const response = await fetch(
            `${
                config().cdnUrl
            }/common/translations/${language}/terms-and-conditions.md`
        );
        const body = await response.text();
        setTosText(body);
    }, [language]);

    useEffect(() => {
        void fetchData();
    }, [language]);

    return (
        <Layout>
            <Title>{t(`policy.terms_and_conditions.title`)}</Title>
            <Body>
                <ReactMarkdown>{tosText}</ReactMarkdown>
            </Body>
        </Layout>
    );
};

export default TermsAndConditions;
