import { IconType } from './icon-types';
import styled from 'styled-components';
import { AdditionalRules } from '../../types/common';

const StyledSvg = styled.svg<{
    $height?: string;
    $width?: string;
    $size?: string;
    $additional?: AdditionalRules['additional'];
}>`
    ${({ $size, $height, $width }) => `
        height: ${$size || $height || '20px'};
        width: ${$size || $width || '20px'};
    `}
    ${({ $additional }) => $additional}
`;

export interface IconProps {
    name: IconType;
    height?: string;
    width?: string;
    size?: string;
    className?: string;
}

const Icon = ({
    name,
    height,
    width,
    size,
    className,
    additional,
}: IconProps & AdditionalRules) => (
    <StyledSvg
        className={className}
        $height={height}
        $width={width}
        $size={size}
        $additional={additional}
    >
        <use href={`#${name}`} />
    </StyledSvg>
);

export default Icon;
