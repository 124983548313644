import { useState, useEffect } from 'react';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

/**
 * ### Hook returns true if viewport is less than 768 pixel
 * @return boolean
 */
export default function useIsMobileView() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    function handleResize() {
        setWindowDimensions(getWindowDimensions());
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', handleResize);
        }

        return () => window?.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions.width < 768;
}
