import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { OAUTH_BASE_URL } from 'jouri-oauth/oauth.const';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import authApiEndpoints from './auth.endpoint';
import {
    AccessTokenSuccess,
    AuthApiCalls,
    OAuthHttpResponse,
} from './auth.model';

const axiosConfig: AxiosRequestConfig = {
    baseURL: OAUTH_BASE_URL,
    withCredentials: true,
};

const http = axios.create(axiosConfig);

http.interceptors.response.use(
    (response: AxiosResponse) => {
        return response && response.data;
    },
    (error: any) => {
        return Promise.reject(error.response);
    }
);

interface UseAuthApiProps {
    clientId: string;
}

const useAuthApi = ({ clientId }: UseAuthApiProps): AuthApiCalls => {
    const { i18n } = useTranslation();
    useEffect(() => {
        http.defaults.headers.common['x-client-id'] = clientId;
    }, []);

    const generateApiCalls = (): AuthApiCalls => {
        return {
            postLogin: (
                preSessionId: string
            ): OAuthHttpResponse<AccessTokenSuccess> => {
                return http.get(authApiEndpoints.postLogin, {
                    headers: {
                        ['x-session-id']: preSessionId,
                    },
                });
            },
            loginWithRedirect: (code: string) => {
                const url = `${OAUTH_BASE_URL}/?code=${encodeURIComponent(
                    code
                )}&clientId=${clientId}&lang=${i18n.language}`;
                window.location.href = url; //WILL BE CHANGE
            },
            token: (code?: string): OAuthHttpResponse<AccessTokenSuccess> => {
                const customHeader: any = {};

                if (code) {
                    customHeader['x-auth-token'] = decodeURIComponent(code);
                }
                return http.get(authApiEndpoints.token, {
                    headers: customHeader,
                });
            },
            logout: () => http.get(authApiEndpoints.logout),
        };
    };
    const songCallsRef = useRef<AuthApiCalls>(generateApiCalls());

    return songCallsRef.current;
};

export default useAuthApi;
