import styled, { css } from 'styled-components';
import Icon from '../../svg-icon/Icon';
import { Title3Bold } from '../../styled/typography';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routesPaths } from '../../../config/routes';
// import config from '../../../config';
import { useEffect } from 'react';
import ChangeLanguage from '../../change-language/changeLanguage';

const LinkStyles = css`
    text-decoration: none;
    color: var(--black);

    &:hover {
        text-decoration: underline;
    }
`;

const MenuHeader = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    color: var(--black);
    justify-content: flex-end;
    align-items: center;
`;

const CloseButton = styled.button`
    padding: 0;
    border: none;
    background: none;
    margin-right: 15px;
    color: var(--black);
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Title = styled(Title3Bold)`
    margin-bottom: 20px;
    margin-top: 20px;
`;

const StyledLink = styled(Link)`
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    ${LinkStyles}
`;

const StyledExternalLink = styled.a`
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    ${LinkStyles}
`;

type MobileDrawerMenuContentProps = {
    onClose: () => void;
};

const MobileDrawerMenuContent = ({ onClose }: MobileDrawerMenuContentProps) => {
    const { t } = useTranslation();
    let location = useLocation();

    useEffect(() => {
        onClose();
    }, [location]);

    return (
        <>
            <MenuHeader>
                <CloseButton onClick={onClose}>
                    <Icon name={'close_icon'} />
                </CloseButton>
            </MenuHeader>
            <ContentWrapper>
                <Title>{t('footer.links.mis_pay')}</Title>
                <StyledLink to={routesPaths.aboutUs}>
                    {t('footer.links.about_us')}
                </StyledLink>
                <StyledLink to={routesPaths.careers}>
                    {t('footer.links.careers')}
                </StyledLink>
                <StyledLink to={routesPaths.shariyaCompliance}>
                    {t('footer.links.shariya_compliance')}
                </StyledLink>
                <Title>{t('footer.links.shop')}</Title>
                <StyledLink to={routesPaths.howItWorks}>
                    {t('footer.links.how_it_works')}
                </StyledLink>
                {/* <StyledLink to={routesPaths.shop}>
                    {t('footer.links.store_directory')}
                </StyledLink> */}
                <Title>{t('footer.links.for_business')}</Title>
                <StyledLink to={routesPaths.forBusiness}>
                    {t('footer.links.partners')}
                </StyledLink>
                {/* <StyledExternalLink
                    href={config().merchantDashboardUrl}
                    target="_blank"
                >
                    {t('footer.links.merchant_login')}
                </StyledExternalLink> */}
                <Title>{t('footer.links.questions')}</Title>
                <StyledLink to={routesPaths.frequentlyAskedQuestions}>
                    {t('footer.links.help_center')}
                </StyledLink>
                <ChangeLanguage />
            </ContentWrapper>
        </>
    );
};

export default MobileDrawerMenuContent;
