import styled from 'styled-components';
import Details, { Entity, SectionList } from '../../components/entity/Details';
import { Title2Medium } from '../../components/styled/typography';
import UnderlinedTextStyle from '../../components/styled/css/UnderlinedTextStyle';
import screenRelativeTo1440 from '../../components/styled/utility/screenRelativeTo1440';
import media from 'styled-media-query';
import useIsMobileView from '../../common/hooks/useIsMobileView';

const Container = styled.section`
    padding: 95px ${screenRelativeTo1440('120px')};
    display: flex;
    flex-direction: column;

    ${media.lessThan('medium')`
    padding-left: 15px;
    padding-right: 15px;
 
    `}
`;

const Title = styled(Title2Medium)`
    margin-bottom: 74px;
    ${UnderlinedTextStyle}

    ${media.lessThan('medium')`
    padding: 0 20px 0 0;
    `}
`;

const Description = styled(Details)`
    padding-left: 0;

    ${SectionList} {
        padding-left: 16px;
    }
`;

interface JobDescriptionProps {
    entity: Entity;
}

const JobDescription = ({ entity }: JobDescriptionProps) => {
    const isMobileView = useIsMobileView();

    return (
        <Container>
            <Title
                underline={{
                    color: 'var(--secondary__medium)',
                    width: '58px',
                    height: isMobileView ? '6px' : '10px',
                    bottom: isMobileView ? '-6px' : undefined,
                }}
            >
                {entity.title}
            </Title>
            <Description
                entity={entity}
                border={false}
                summary={false}
                open
                action={false}
            />
        </Container>
    );
};

export default JobDescription;
