import { Card, CardBody, CardHeader, CardTitle } from 'components/styled/Card';
import {
    Title1Bold as BaseTitle1Bold,
    Title1Medium as BaseTitle1Medium,
} from 'components/styled/typography';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import media from 'styled-media-query';
import useIsMobileView from '../../common/hooks/useIsMobileView';
import screenRelativeTo1440 from '../../components/styled/utility/screenRelativeTo1440';

const Container = styled.section`
    padding: 96px ${screenRelativeTo1440('130px')} 0;

    ${media.lessThan('medium')`
    padding: 56px 15px 40px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    `}
`;

const Cards = styled.div`
    display: flex;
    justify-content: space-between;
    row-gap: 32px;
    column-gap: 24px;

    ${media.lessThan('medium')`
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    `}
`;

const Header = styled.div`
    display: flex;
    margin-bottom: 72px;
    column-gap: 8px;

    ${media.lessThan('medium')`
    margin-bottom: 24px;
    `}
`;

const Title1Bold = styled(BaseTitle1Bold)`
    ${media.lessThan('medium')`
        font-size: 32px;
        line-height: 36px;
    `}
    ${media.lessThan('small')`
        font-size: 22px;
        line-height: 24px;
    `}
`;

const Title1Medium = styled(BaseTitle1Medium)`
    ${media.lessThan('medium')`
        font-size: 32px;
        line-height: 36px;
    `}
    ${media.lessThan('small')`
        font-size: 22px;
        line-height: 24px;
    `}
`;

const HowToEnjoy = () => {
    const { t } = useTranslation();
    const isMobileView = useIsMobileView();

    return (
        <Container>
            <Header>
                <Title1Bold>
                    {t('how_it_works.how_to_enjoy.title.first')}
                </Title1Bold>
                <Title1Medium>
                    {t('how_it_works.how_to_enjoy.title.second')}
                </Title1Medium>
            </Header>
            <Cards>
                {['first', 'second', 'third'].map((key) => (
                    <Card
                        min-height="240px"
                        width={isMobileView ? '90%' : '380px'}
                        key={key}
                    >
                        <CardHeader
                            circle={{
                                size: isMobileView ? 64 : 100,
                                left: -25,
                                top: isMobileView ? -30 : -40,
                                color: 'yellow',
                            }}
                            additional={
                                isMobileView
                                    ? { minHeight: '32px' }
                                    : { height: '64px' }
                            }
                        >
                            <CardTitle>
                                {t(
                                    `how_it_works.how_to_enjoy.steps.${key}.title`
                                )}
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            {t(`how_it_works.how_to_enjoy.steps.${key}.body`)}
                        </CardBody>
                    </Card>
                ))}
            </Cards>
        </Container>
    );
};

export default HowToEnjoy;
