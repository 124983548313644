import ActionCall from 'components/action-call/ActionCall';
import Hero from 'components/hero/Hero';
import {
    Body1Medium,
    H3Bold,
    H3Light,
    H3Medium,
    Title1Medium,
    Title2Regular,
} from 'components/styled/typography';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Features from './Features';
import HowToEnjoy from './HowToEnjoy';
import HowToUse from './HowToUse';
import { useNavigate } from 'react-router-dom';
import { routesPaths } from '../../config/routes';
import ActionCallMobileView from '../../components/action-call/ActionCallMobileView';
import useIsMobileView from '../../common/hooks/useIsMobileView';
import media from 'styled-media-query';
import useOptionalLayout from 'common/hooks/useOptionalLayout';
import BaseLayout from 'components/layouts/base-layout';

const Question = styled(ActionCall)`
    padding-top: 80px;
`;

const Message = styled(H3Bold)`
    color: var(--grey__medium);

    ${media.lessThan('medium')`
    font-size: 28px;
    `}
`;
const ThirdLineText = styled(Title2Regular)`
    font-weight: 300;
`;
const HeroContainer = styled.div`
    margin-bottom: 250px;
`;

const HowItWorks = () => {
    const { Layout, hideHrefs } = useOptionalLayout(BaseLayout, true);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMobileView = useIsMobileView();
    const QuestionsComponent = isMobileView ? ActionCallMobileView : Question;

    const navigateToFrequentlyAskedQuestions = () => {
        navigate(routesPaths.frequentlyAskedQuestions);
    };

    return (
        <Layout>
            {!hideHrefs && (
                <HeroContainer>
                    <Hero
                        heroText={{
                            firstLine: {
                                text: t('how_it_works.hero.header.first'),
                                component: isMobileView
                                    ? Title1Medium
                                    : H3Medium,
                            },
                            secondLine: {
                                text: t('how_it_works.hero.header.second'),
                                component: isMobileView
                                    ? Title2Regular
                                    : H3Light,
                            },
                            thirdLine: {
                                text: t('how_it_works.hero.header.third'),
                                component: isMobileView
                                    ? ThirdLineText
                                    : H3Light,
                            },
                        }}
                    />
                </HeroContainer>
            )}
            <Features />
            <HowToEnjoy />
            <HowToUse />
            {!hideHrefs && (
                <QuestionsComponent
                    messages={{
                        first: {
                            text: t('how_it_works.questions.message'),
                            component: Message,
                        },
                    }}
                    button={{
                        text: t('how_it_works.questions.button'),
                        component: Body1Medium,
                    }}
                    onActionClick={navigateToFrequentlyAskedQuestions}
                />
            )}
        </Layout>
    );
};

export default HowItWorks;
