import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorScreen, {
    ERRORS,
    ErrorType,
} from '@jouri/components/dist/components/error-screen';

import config from 'config';
import { useNavigate } from 'react-router-dom';
import { routesPaths } from 'config/routes';

const CONTENT = {
    TITLE: {
        [ERRORS[404]]: 'general.general_pageNotFound_title',
        [ERRORS[500]]: 'general.general_internalServerError_title',
    },
    DESCRIPTION: {
        [ERRORS[404]]: 'general.general_pageNotFound_text',
        [ERRORS[500]]: 'general.general_internalServerError_text',
        [ERRORS.E0075]: 'error_codes.error_E0075_errorMessage',
        [ERRORS.E0076]: 'error_codes.error_E0076_errorMessage',
        [ERRORS.E0077]: 'error_codes.error_E0077_errorMessage',
        [ERRORS.E0078]: 'error_codes.error_E0078_errorMessage',
    },
    BUTTON_TEXT: 'general.general_goToHome_button',
};

const DEFAULT_ERROR = ErrorType.ERR_404;

const ErrorPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const goToHome = useCallback(() => {
        navigate(routesPaths.root);
    }, []);

    return (
        <ErrorScreen
            cdnUrl={config().cdnUrl || ''}
            errorType={DEFAULT_ERROR}
            title={t(CONTENT.TITLE[DEFAULT_ERROR])}
            description={t(CONTENT.DESCRIPTION[DEFAULT_ERROR])}
            buttonText={t(CONTENT.BUTTON_TEXT)}
            onButtonClick={goToHome}
        />
    );
};

export default ErrorPage;
