import Shariyatitle from './ShariyaTitle';
import Information from './Information';
import useOptionalLayout from 'common/hooks/useOptionalLayout';
import BaseLayout from 'components/layouts/base-layout';

const ShariyaCompliance = () => {
    const { Layout } = useOptionalLayout(BaseLayout);

    return (
        <Layout>
            <Shariyatitle />
            <Information />
        </Layout>
    );
};

export default ShariyaCompliance;
