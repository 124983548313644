import styled from 'styled-components';
import {
    H3Medium,
    Title1Regular,
    Title2Medium,
    Title3Regular,
} from '../../components/styled/typography';
import CenteredActionCall, {
    TitleSecondLine,
} from '../../components/action-call/CenteredActionCall';
import { useTranslation } from 'react-i18next';
import BackgroundCircle from '../../components/styled/BackgroundCircle';
import media from 'styled-media-query';
import useIsMobileView from '../../common/hooks/useIsMobileView';

const Container = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--primary__thin__80);
    padding: 68px 0 76px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    ${TitleSecondLine} {
        width: 830px;
        ${media.lessThan('medium')`
    width: unset;
    `}
    }

    ${media.lessThan('medium')`
    padding: 68px 30px 76px 30px; 
    `}
`;

const H3MediumCentered = styled(Title2Medium)`
    text-align: center;
`;

const Shariyatitle = () => {
    const { t } = useTranslation();
    const isMobileView = useIsMobileView();

    return (
        <Container>
            <CenteredActionCall
                firstLine={{
                    component: isMobileView ? H3MediumCentered : H3Medium,
                    text: t('shariya_compliance.shariya_title.lines.0'),
                }}
                secondLine={{
                    component: isMobileView ? Title3Regular : Title1Regular,
                    text: t('shariya_compliance.shariya_title.lines.1'),
                }}
            />
            <BackgroundCircle
                size="103px"
                color="var(--primary__x-light__70)"
                additional={{ bottom: '40px', left: '29%' }}
            />
            <BackgroundCircle
                size="52px"
                color="var(--primary__x-light)"
                additional={{ top: '90px', right: '25%' }}
            />
        </Container>
    );
};

export default Shariyatitle;
