import { Card, CardBody, CardHeader, CardTitle } from 'components/styled/Card';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import screenRelativeTo1440 from '../../components/styled/utility/screenRelativeTo1440';
import media from 'styled-media-query';
import useIsMobileView from '../../common/hooks/useIsMobileView';

const Container = styled.section`
    padding: 72px ${screenRelativeTo1440('120px')} 0;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    ${media.lessThan('medium')`
        flex-direction: column;
        align-items: center;
    `}
`;

const Features = () => {
    const { t } = useTranslation();
    const isMobileView = useIsMobileView();

    return (
        <Container>
            {['first', 'second', 'third'].map((key) => (
                <Card key={key} width={isMobileView ? '80%' : undefined}>
                    <CardHeader>
                        <CardTitle>
                            {t(`how_it_works.features.${key}.title`)}
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        {t(`how_it_works.features.${key}.body`)}
                    </CardBody>
                </Card>
            ))}
        </Container>
    );
};

export default Features;
