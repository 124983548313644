import styled, { css } from 'styled-components';
import { Body2Regular, Title2Medium } from '../../components/styled/typography';
import UnderlinedTextStyle from '../../components/styled/css/UnderlinedTextStyle';
import BaseButton from '../../components/styled/Button';
import media from 'styled-media-query';

export const FormElementStyle = css`
    border: 1px solid var(--grey__x-light);
    border-radius: 4px;
    height: 48px;
    padding: 0 16px;
    font-size: 16px;
    line-height: 22px;
    box-sizing: border-box;
    outline: none;
`;

export const Container = styled.section`
    padding: 95px 120px;

    ${media.lessThan('medium')`
    padding: unset;
    margin-bottom: 20px;
    `}
`;

export const FormContainer = styled.div`
    background: var(--grey__medium);
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
`;

export const Form = styled.form`
    width: 430px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    ${media.lessThan('medium')`
    width: unset;
    padding: 0 20px 0 20px;
    `}
`;

export const Title = styled(Title2Medium).attrs(() => ({
    underline: {
        color: 'var(--primary__default)',
        width: '58px',
        height: '10px',
    },
}))`
    color: white;
    margin-bottom: 90px;
    ${UnderlinedTextStyle}

    ${media.lessThan('medium')`
    width: 100%;
    `}
`;

export const Label = styled.label<{ compact?: boolean }>`
    color: white;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    ${({ compact }) => {
        if (compact) {
            return { width: 'calc(50% - 12px)', marginBottom: '50px' };
        } else {
            return { width: '100%', marginBottom: '34px' };
        }
    }}
`;

export const LabelText = styled(Body2Regular)`
    margin-bottom: 6px;
    color: white;
`;

export const Input = styled.input`
    ${FormElementStyle}

    &:disabled {
        background: #fafafa;
    }
`;

export const Select = styled.select`
    ${FormElementStyle}
`;

export const CVButton = styled(BaseButton).attrs(() => ({
    type: 'button',
}))`
    width: 100%;
    margin-bottom: 44px;
    text-align: center;
    background: #d9d9d9;
    height: 48px;
    &:hover {
        background: var(--primary__default);
    }
`;

export const SelectedFile = styled.div`
    color: #fff;
    margin-top: -25px;
`;

export const SubmitButton = styled(BaseButton)`
    background: var(--primary__default);
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    margin-left: auto;
    width: 144px;
    &:disabled {
        background: #d9d9d9;
        cursor: not-allowed;
    }
`;
