import {
    Body1Medium,
    H3Light,
    H3Medium,
    H3Regular,
    Title1Light,
    Title1Medium,
    Title3Medium,
    Title3Regular,
} from 'components/styled/typography';
import { useTranslation } from 'react-i18next';
import ActionCall from '../../components/action-call/ActionCall';
import Hero from '../../components/hero/Hero';
// import FeaturedShops from '../../components/featured-shops/FeaturedShops';
import Features from './Features';
import Steps from './Steps';
import useIsMobileView from '../../common/hooks/useIsMobileView';
import ActionCallMobileView from '../../components/action-call/ActionCallMobileView';
function sendEmail(address: string) {
    window.location.href = 'mailto:' + address;
}
const Home = () => {
    const { t } = useTranslation();
    const isMobileView = useIsMobileView();

    const ActionCallComponent = isMobileView
        ? ActionCallMobileView
        : ActionCall;
    return (
        <>
            <Hero
                heroText={{
                    firstLine: {
                        text: t('home_page.hero.header.first'),
                        component: isMobileView ? Title1Medium : H3Medium,
                    },
                    secondLine: {
                        text: t('home_page.hero.header.second'),
                        component: isMobileView ? Title1Light : H3Light,
                    },
                    thirdLine: {
                        text: t('home_page.hero.header.third'),
                        component: isMobileView ? Title1Light : H3Light,
                    },
                }}
            />
            <Features />
            {/* <FeaturedShops callToAction /> */}
            <Steps />

            <ActionCallComponent
                messages={{
                    first: {
                        text: t('home_page.contact_us.messages.first'),
                        component: isMobileView ? Title3Regular : H3Regular,
                    },
                    second: {
                        text: t('home_page.contact_us.messages.second'),
                        component: isMobileView ? Title3Medium : H3Medium,
                    },
                }}
                button={{
                    text: t('home_page.contact_us.button'),
                    component: Body1Medium,
                }}
                onActionClick={() => sendEmail(t('footer.contact.e-mail'))}
            />
        </>
    );
};

export default Home;
