import styled from 'styled-components';
import {
    H3Medium,
    SubtitleBold,
    Title2Medium,
    Title3Medium,
    Title3Regular,
} from '../../components/styled/typography';
import CenteredActionCall from '../../components/action-call/CenteredActionCall';
import { useTranslation } from 'react-i18next';
import BackgroundCircle from '../../components/styled/BackgroundCircle';
import useIsMobileView from '../../common/hooks/useIsMobileView';
import media from 'styled-media-query';

const Container = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--primary__x-light);
    padding: 68px 0 76px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    ${media.lessThan('medium')`
    padding: 68px 30px 76px 30px; 
    `}
`;
const H3MediumCentered = styled(Title2Medium)`
    text-align: center;
`;

const Title3MediumWithZIndex = styled(SubtitleBold)`
    z-index: 1;
`;

const AboutUsTitle = () => {
    const { t } = useTranslation();
    const isMobileView = useIsMobileView();
    return (
        <Container>
            <CenteredActionCall
                firstLine={{
                    component: isMobileView ? H3MediumCentered : H3Medium,
                    text: t('about_us.title.0'),
                }}
                secondLine={{
                    component: isMobileView ? Title3Regular : H3Medium,
                    text: t('about_us.title.1'),
                }}
                thirdLine={{
                    firstPart: {
                        component: isMobileView
                            ? Title3MediumWithZIndex
                            : Title3Medium,
                        text: t('about_us.title.2'),
                    },
                }}
            />
            <BackgroundCircle
                size="103px"
                color="var(--primary__light)"
                additional={{ bottom: '83px', right: '28%' }}
            />
        </Container>
    );
};

export default AboutUsTitle;
