import i18n from 'assets/i18n';
import {
    Body1Medium,
    BodyLight,
    SubtitleMedium,
} from 'components/styled/typography';
import { useTranslation } from 'react-i18next';
import styled, { StyledComponent } from 'styled-components';
import media from 'styled-media-query';
import useIsMobileView from '../../common/hooks/useIsMobileView';
import screenRelativeTo1440 from '../styled/utility/screenRelativeTo1440';
import Icon from '../svg-icon/Icon';
import TextWithCircle from '../text-with-circle/TextWithCircle';

const Container = styled.section`
    background: #fff9ef;
    width: 100%;
    height: 540px;
    position: relative;
    display: flex;
    padding: 72px ${screenRelativeTo1440('120px')} 25px;

    ${media.lessThan('medium')`
    flex-direction: column;
    height: 1000px;
    margin-bottom: 200px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    `}

    ${media.between('medium', 'large')`
    padding-left: 40px;
    `}
`;

const HeroTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 800px;

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    align-items: center;
    width: 100%;
    `}
`;

const Messages = styled.div`
    display: flex;
    margin-top: 50px;

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    flex-wrap: wrap;
    flex-basis: 
    max-width: 100vw;
    align-items: center;
    justify-content: center;
    `}
`;

const Social = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 60px;

    ${media.lessThan('medium')`
    align-items: center;
    `}
`;

const SocialIcons = styled.div`
    display: flex;
    margin-top: 20px;
`;

const AppPreview = styled.div`
    position: relative;
    top: -90px;
    width: 490px;
    height: 600px;
    display: flex;

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    display: none;
    `}
`;

const AppPreviewLeftCircle = styled(Icon)`
    position: absolute;
    left: 0;
    top: 0;
`;

const AppPreviewRightCircle = styled(Icon)`
    position: absolute;
    right: 0;
    bottom: 75px;

    ${media.lessThan('medium')`
    bottom: 120px;
    `}
`;

const AppPreviewImage = styled.img.attrs(() => ({
    src:
        process.env.REACT_APP_COMMON_CDN_URL +
        `/web/app-preview${i18n.language === 'ar' ? '-ar' : ''}.png`,
}))`
    position: absolute;
    top: 50px;
    left: 170px;
    border-radius: 30px;

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    left: unset;
    `}
`;

const AppPreviewMobileView = styled.div`
    position: relative;
    min-width: 350px;
    width: 100%;
    height: 650px;
    display: none;
    justify-content: center;

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    display: flex;
  `}
`;

const JoinNowButton = styled.button`
    cursor: pointer;
    margin-top: 20px;
    width: 164px;
    height: 40px;
    display: none;
    color: #33b9bf;
    background-color: inherit;
    margin-right: 6px;
    border: 1px solid #33b9bf;
    border-radius: 4px;

    ${media.lessThan('medium')`
    display: block;
    `}
`;

interface Line {
    text: string;
    component: StyledComponent<any, any>;
}

interface HeroProps {
    heroText: {
        firstLine: Line;
        secondLine?: Line;
        thirdLine?: Line;
    };
}

const Hero = ({ heroText }: HeroProps) => {
    const { t } = useTranslation();
    const FirstLine = heroText.firstLine.component;
    const SecondLine = heroText.secondLine?.component;
    const ThirdLine = heroText.thirdLine?.component;
    const isMobileView = useIsMobileView();

    return (
        <Container>
            <HeroTextContainer>
                <TextWithCircle circle={{ size: 37, left: -6 }}>
                    <FirstLine additional={{ position: 'relative' }}>
                        {heroText.firstLine.text}
                    </FirstLine>
                </TextWithCircle>
                {SecondLine && (
                    <SecondLine additional={{ marginTop: '14px' }}>
                        {heroText.secondLine?.text}
                    </SecondLine>
                )}
                {ThirdLine && <ThirdLine>{heroText.thirdLine?.text}</ThirdLine>}
                <JoinNowButton
                    onClick={() => {
                        window.location.href = `/onboarding?lang=${i18n.language}`;
                    }}
                >
                    {t('home_page.hero.joinMessage')}
                </JoinNowButton>
                <Messages>
                    <TextWithCircle
                        additional={{
                            marginRight: isMobileView ? '24px' : '48px',
                            marginLeft: isMobileView ? '24px' : 'unset',
                        }}
                        circle={{ size: 16, top: 8, left: -6 }}
                    >
                        <SubtitleMedium additional={{ position: 'relative' }}>
                            {t('home_page.hero.messages.first')}
                        </SubtitleMedium>
                    </TextWithCircle>
                    <TextWithCircle
                        additional={{
                            marginRight: isMobileView ? '24px' : '48px',
                            marginLeft: isMobileView ? '24px' : 'unset',
                        }}
                        circle={{
                            size: 16,
                            top: 8,
                            left: -6,
                            color: 'yellow',
                        }}
                    >
                        <SubtitleMedium additional={{ position: 'relative' }}>
                            {t('home_page.hero.messages.second')}
                        </SubtitleMedium>
                    </TextWithCircle>
                    <TextWithCircle
                        additional={{
                            marginRight: isMobileView ? '24px' : '48px',
                            marginLeft: isMobileView ? '24px' : 'unset',
                        }}
                        circle={{
                            size: 16,
                            top: 8,
                            left: -6,
                            color: 'red',
                        }}
                    >
                        <SubtitleMedium additional={{ position: 'relative' }}>
                            {t('home_page.hero.messages.third')}
                        </SubtitleMedium>
                    </TextWithCircle>
                    <TextWithCircle
                        additional={{
                            marginRight: isMobileView ? '24px' : '48px',
                            marginLeft: isMobileView ? '24px' : 'unset',
                        }}
                        circle={{ size: 16, top: 8, left: -6 }}
                    >
                        <SubtitleMedium additional={{ position: 'relative' }}>
                            {t('home_page.hero.messages.fourth')}
                        </SubtitleMedium>
                    </TextWithCircle>
                    <TextWithCircle
                        additional={{
                            marginRight: isMobileView ? '24px' : '48px',
                            marginLeft: isMobileView ? '24px' : 'unset',
                        }}
                        circle={{
                            size: 16,
                            top: 8,
                            left: -6,
                            color: 'yellow',
                        }}
                    >
                        <SubtitleMedium additional={{ position: 'relative' }}>
                            {t('home_page.hero.messages.fifth')}
                        </SubtitleMedium>
                    </TextWithCircle>
                </Messages>

                <Social>
                    <AppPreviewMobileView>
                        <AppPreviewLeftCircle
                            name="yellow-circle"
                            size="300px"
                        />
                        <AppPreviewRightCircle
                            name="blue-circle"
                            size="140px"
                        />
                        <AppPreviewImage />
                    </AppPreviewMobileView>
                    <Body1Medium additional={{ textAlign: 'center' }}>
                        {t('home_page.hero.social.first')}
                    </Body1Medium>
                    <BodyLight>{t('home_page.hero.social.second')}</BodyLight>
                    <SocialIcons>
                        <a
                            target="_blank"
                            href="https://play.google.com/store/apps/details?id=com.mispaymobile"
                        >
                            <Icon
                                name="play-store--dark"
                                height="40px"
                                width="140px"
                                additional={{ marginRight: '16px' }}
                            />
                        </a>
                        <a
                            target="_blank"
                            href="https://apps.apple.com/gb/app/mispay/id6443602765"
                        >
                            <Icon
                                name="app-store--dark"
                                height="40px"
                                width="140px"
                            />
                        </a>
                    </SocialIcons>
                </Social>
            </HeroTextContainer>
            <AppPreview>
                <AppPreviewLeftCircle name="yellow-circle" size="410px" />
                <AppPreviewRightCircle name="blue-circle" size="190px" />
                <AppPreviewImage />
            </AppPreview>
        </Container>
    );
};

export default Hero;
