import styled, { css } from 'styled-components';
import { AdditionalRules } from 'types/common';

const BasicTypography = css<AdditionalRules>`
    font-family: 'MISPayFontFamily', sans-serif;
    letter-spacing: -0.19px;
    position: relative;
    color: var(--grey__dark);
    ${({ additional }) => additional}
`;

export const H3Light = styled.h3<AdditionalRules>`
    font-size: 48px;
    line-height: 64px;
    font-weight: 300;
    ${BasicTypography}
`;

export const H3Regular = styled.h3<AdditionalRules>`
    font-size: 48px;
    line-height: 64px;
    font-weight: 400;
    ${BasicTypography}
`;

export const H3Medium = styled.h3<AdditionalRules>`
    font-size: 48px;
    line-height: 64px;
    font-weight: 500;
    ${BasicTypography}
`;

export const H3Bold = styled.h3<AdditionalRules>`
    font-size: 48px;
    line-height: 64px;
    font-weight: 700;
    ${BasicTypography}
`;

export const SubtitleRegular = styled.span<AdditionalRules>`
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    ${BasicTypography}
`;

export const SubtitleMedium = styled.span<AdditionalRules>`
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    ${BasicTypography}
`;

export const SubtitleBold = styled.span<AdditionalRules>`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    ${BasicTypography}
`;

export const Body1Medium = styled.span<AdditionalRules>`
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    ${BasicTypography}
`;

export const Body1Regular = styled.span<AdditionalRules>`
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    ${BasicTypography}
`;

export const Body2Regular = styled.span<AdditionalRules>`
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    ${BasicTypography}
`;

export const BodyLight = styled.span<AdditionalRules>`
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    ${BasicTypography}
`;

export const Title3Regular = styled.span<AdditionalRules>`
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    ${BasicTypography}
`;

export const Title3Medium = styled.span<AdditionalRules>`
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    ${BasicTypography}
`;

export const Title3Bold = styled.span<AdditionalRules>`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    ${BasicTypography}
`;

export const Title2Regular = styled.span<AdditionalRules>`
    font-size: 32px;
    line-height: 42px;
    font-weight: 400;
    ${BasicTypography}
`;

export const Title2Medium = styled.span<AdditionalRules>`
    font-size: 32px;
    line-height: 42px;
    font-weight: 500;
    ${BasicTypography}
`;

export const Title2Bold = styled.span<AdditionalRules>`
    font-size: 32px;
    line-height: 42px;
    font-weight: 700;
    ${BasicTypography}
`;

export const Title1Regular = styled.span<AdditionalRules>`
    font-size: 40px;
    line-height: 54px;
    font-weight: 400;
    ${BasicTypography}
`;

export const Title1Medium = styled.span<AdditionalRules>`
    font-size: 40px;
    line-height: 54px;
    font-weight: 500;
    ${BasicTypography}
`;

export const Title1Light = styled.span<AdditionalRules>`
    font-size: 40px;
    line-height: 54px;
    font-weight: 300;
    ${BasicTypography}
`;

export const Title1Bold = styled.span<AdditionalRules>`
    font-size: 40px;
    line-height: 54px;
    font-weight: 700;
    ${BasicTypography}
`;
