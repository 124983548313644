import {
    SubtitleRegular,
    Title2Bold,
    Title2Medium,
} from 'components/styled/typography';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
    Card as BaseCard,
    CardHeader as BaseCardHeader,
} from '../../components/styled/Card';
import screenRelativeTo1440 from '../../components/styled/utility/screenRelativeTo1440';
import media from 'styled-media-query';

const Container = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 60px ${screenRelativeTo1440('120px')};

    &:before {
        background: var(--secondary__thin);
        opacity: 0.4;
        width: 100%;
        height: 100%;
        content: 'background';
        color: transparent;
        position: absolute;
        top: 0;
        left: 0;
    }

    ${media.lessThan('medium')`
    &:before {
        top: 0;
        left: 0;
        background: unset;
    }
    background: var(--secondary__thin__OP40);
    padding: 40px 15px;
  `}
`;

const Header = styled.div`
    margin: 0 auto;
`;

const Cards = styled.div`
    display: flex;
    margin-top: 32px;

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    flex-direction: column;
    align-items: center;
    
    > * {
        margin-top: 16px;
    }    
    `}
`;

const Card = styled(BaseCard)`
    padding: 40px 0 20px;
    background: var(--grey__x-thin);
    margin-right: 32px;

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    margin-right: unset;  
    background: white;  
    width: 85%;
    `}
`;

const CardHeader = styled(BaseCardHeader).attrs(() => ({
    circle: {
        size: 51,
        top: -4,
        left: -15,
    },
}))`
    margin: 0 auto;
`;

const CardTitle = styled(Title2Bold)`
    color: var(--grey__medium);
`;

const CardBody = styled(SubtitleRegular)`
    margin: 30px auto 0;
    text-align: center;
    width: 160px;

    &:last-of-type {
        width: 190px;
    }

    ${media.lessThan('medium')`
    width: unset;
    `}
`;

const Title1 = styled(Title2Medium)`
    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    font-size: 24px;   
    `}
`;
const Title2 = styled(Title2Bold)`
    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    font-size: 24px;   
    `}
`;

const Numbers = () => {
    const { t } = useTranslation();

    const cards: Array<{ title: string; body: string }> = t(
        'for_business.numbers.cards',
        { returnObjects: true }
    );

    return (
        <Container>
            <Header>
                <Title1>{t('for_business.numbers.title.0')}</Title1>
                <Title2>{t('for_business.numbers.title.1')}</Title2>
            </Header>
            <Cards>
                {cards.map((card, index) => (
                    <Card key={index}>
                        <CardHeader>
                            <CardTitle>{card.title}</CardTitle>
                        </CardHeader>
                        <CardBody>{card.body}</CardBody>
                    </Card>
                ))}
            </Cards>
        </Container>
    );
};

export default Numbers;
