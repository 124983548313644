import { appRoutes } from 'config/routes';
import React, {
    Fragment,
    Suspense,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthGuard from 'common/auth/AuthGuard';
import config from 'config';
import JouriOAuthProvider from 'jouri-oauth/JouriOAuth';
import { Loading } from '@jouri/components';
import * as S from './index.styles';
export interface AppRoute {
    exact?: boolean;
    path: string;
    component?: any;
    layout?: any;
    guard?: typeof Fragment | typeof AuthGuard;
    guardCondition?: boolean;
    before?: () => Promise<any>;
    redirect?: string;
}

interface RouteComposerProps {
    before?: () => Promise<any>;
    route: Pick<
        AppRoute,
        'component' | 'layout' | 'guard' | 'redirect' | 'guardCondition'
    >;
}

const RouteComposer = ({ route, before, ...restProps }: RouteComposerProps) => {
    const Component = route.component || React.Fragment;
    const Guard = route.guard || React.Fragment;
    const Layout = route.layout || React.Fragment;

    const [ready, setReady] = useState<boolean>(false);
    const [initValue, setInitValue] = useState<any>();

    const beforeHandler = useCallback(async () => {
        let callbackValue;
        if (before) {
            callbackValue = await before();
        }
        setInitValue(callbackValue);
        setReady(true);
    }, [before]);

    useEffect(() => {
        void beforeHandler();
    }, [beforeHandler]);

    const props = route.guard
        ? {
              guardCondition: route?.guardCondition,
              redirect: route?.redirect,
          }
        : {};

    return (
        <>
            {ready ? (
                <Guard {...props}>
                    <Layout>
                        <Component {...restProps} initValue={initValue} />
                    </Layout>
                </Guard>
            ) : (
                <S.LoadingContainer>
                    <Loading />
                </S.LoadingContainer>
            )}
        </>
    );
};

const renderRoutes = (routes: AppRoute[]) =>
    routes ? (
        <Routes>
            {appRoutes.map((route) => {
                return (
                    <Route
                        key={route.path}
                        path={route.path}
                        index={route?.exact}
                        element={
                            <RouteComposer
                                before={route?.before}
                                route={{
                                    component: route.component,
                                    layout: route.layout,
                                    guard: route.guard,
                                    redirect: route.redirect,
                                    guardCondition: route.guardCondition,
                                }}
                            />
                        }
                    />
                );
            })}
        </Routes>
    ) : null;

function AppRoutes() {
    return (
        <Suspense fallback={'loading'}>
            <JouriOAuthProvider clientId={config().oauthClientId}>
                {renderRoutes(appRoutes)}
            </JouriOAuthProvider>
        </Suspense>
    );
}

export default AppRoutes;
