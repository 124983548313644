import styled, { StyledComponent } from 'styled-components';
import BaseButton from '../styled/Button';
import Icon from '../svg-icon/Icon';
import React from 'react';

const Container = styled.section`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 0 20px 0 20px;
`;

const Circle = styled(Icon).attrs(() => ({
    size: '220px',
    name: 'red-circle',
}))`
    position: absolute;
    opacity: 0.7;
    top: 0;
    filter: blur(200px);
    z-index: -1;
`;

const Message = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Button = styled(BaseButton)`
    align-self: flex-end;
    margin-top: 48px;
    background: var(--alternative__medium);
`;

interface TextComponent {
    text: string;
    component: StyledComponent<any, any>;
}

interface ActionCallProps {
    messages: {
        first: TextComponent;
        second?: TextComponent;
    };
    button: TextComponent;
    className?: string;
    buttonClassName?: string;
    onActionClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ActionCall = ({
    messages,
    button,
    className,
    buttonClassName,
    onActionClick,
}: ActionCallProps) => {
    const FirstMessage = messages.first.component;
    const SecondMessage = messages.second?.component;
    const ButtonText = button.component;

    return (
        <Container className={className}>
            <Circle />
            <Message>
                <FirstMessage>{messages.first.text}</FirstMessage>
                {SecondMessage && (
                    <SecondMessage>{messages.second?.text}</SecondMessage>
                )}
            </Message>
            <Button className={buttonClassName} onClick={onActionClick}>
                <ButtonText additional={{ color: 'white' }}>
                    {button.text}
                </ButtonText>
            </Button>
        </Container>
    );
};

export default ActionCall;
