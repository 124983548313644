import { SubtitleRegular, Title2Medium } from 'components/styled/typography';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import screenRelativeTo1440 from '../../components/styled/utility/screenRelativeTo1440';
import media from 'styled-media-query';

const Container = styled.section`
    position: relative;
    padding: 40px ${screenRelativeTo1440('120px')};
    margin-top: 96px;
    display: flex;
    flex-direction: column;

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        background: var(--secondary__dark);
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.2;
    }

    ${media.lessThan('medium')`
        margin-top: 10px;
    `}
`;

const Title = styled(Title2Medium)`
    &:after {
        content: '';
        width: 58px;
        height: 10px;
        background: var(--secondary__medium);
        position: absolute;
        bottom: -18px;
        left: 0;
    }

    ${media.lessThan('medium')`
        font-size: 32px;
        line-height: 36px;
        align-self: center;
        
        &:after {
            content: '';
            width: 40px;
            height: 6px;
            background: var(--secondary__medium);
            position: absolute;
            bottom: -18px;
            left: 0;
        }
    `}

    ${media.lessThan('small')`
        font-size: 22px;
        line-height: 24px;
    `}
`;

const Steps = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 104px;

    ${media.lessThan('medium')`
        align-items: center;
        flex-direction: column;
        margin-top: 70px;
    `}
`;

const Card = styled.div<{ backContent: string }>`
    position: relative;
    display: flex;
    background: white;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    border-bottom-left-radius: 50px;
    padding: 0 24px 30px 36px;
    min-height: 180px;
    width: 380px;
    margin-bottom: 40px;
    box-sizing: border-box;

    &:before {
        position: absolute;
        top: 20px;
        left: 28px;
        content: '${({ backContent }) => backContent}';
        font-size: 128px;
        line-height: 88px;
        color: var(--grey__thin);
        opacity: 0.4;
        width: 50px;
        height: 50px;
    }

    ${media.lessThan('medium')`
    width: 80%;
    padding: 0 24px 24px 36px;
    margin-bottom: 16px;
    `}
`;

const CardBody = styled(SubtitleRegular)`
    color: var(--grey__medium);
    margin-top: 70px;
    width: 300px;
`;

const HowToUse = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <Title>{t('how_it_works.how_to_use.title')}</Title>
            <Steps>
                {new Array(6).fill(0).map((_, index) => (
                    <Card backContent={`0${index + 1}`} key={index}>
                        <CardBody>
                            {t(`how_it_works.how_to_use.steps.${index}`)}
                        </CardBody>
                    </Card>
                ))}
            </Steps>
        </Container>
    );
};

export default HowToUse;
