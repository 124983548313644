import { NO_BASE_LAYOUT } from 'common/constants/queryStringParams';
import { coerceBooleanURLParam } from 'common/utils/coerce';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * Returns an optional layout controlled by a URL parameter to be used to wrap the page component.
 */
export default function useOptionalLayout(
    LayoutComponent: React.ElementType,
    hideHrefs?: boolean
) {
    const [searchParams] = useSearchParams();
    const noBaseLayout = coerceBooleanURLParam(
        searchParams.get(NO_BASE_LAYOUT)
    );

    return {
        Layout: noBaseLayout ? React.Fragment : LayoutComponent,
        hideHrefs: noBaseLayout && hideHrefs,
    };
}
