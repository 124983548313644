import { FontFaceStyle } from '@jouri/components';
import { createGlobalStyle } from 'styled-components';

const Styles = createGlobalStyle`
  body {
    font-family: 'MISPayFontFamily'
  }
`;

export default function GlobalStyles() {
    return (
        <>
            <FontFaceStyle />
            <Styles />
        </>
    );
}
