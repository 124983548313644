import styled from 'styled-components';
import {
    SubtitleRegular,
    Title1Bold,
    Title3Bold,
    Title3Medium,
} from '../../components/styled/typography';
import UnderlinedTextStyle from '../../components/styled/css/UnderlinedTextStyle';
import { useTranslation, Trans } from 'react-i18next';
import screenRelativeTo1440 from '../../components/styled/utility/screenRelativeTo1440';
import media from 'styled-media-query';
import useIsMobileView from '../../common/hooks/useIsMobileView';

const Container = styled.section`
    padding: 95px ${screenRelativeTo1440('120px')};
    display: flex;
    flex-direction: column;

    ${media.lessThan('medium')`

    padding: 56px 15px;
    `}
`;

const Title = styled(Title1Bold)`
    margin-bottom: 62px;
    ${UnderlinedTextStyle}

    ${media.lessThan('medium')`
    font-size: 24px;
    `}
`;

const Subtitle = styled(Title3Medium)`
    margin-bottom: 68px;

    ${media.lessThan('medium')`
    font-size: 20px;
    margin-bottom: 40px;
    `}
`;

const Card = styled.div`
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    display: flex;

    &:not(:last-of-type) {
        margin-bottom: 48px;
    }

    ${media.lessThan('medium')`
    flex-direction: column;
    `}
`;

const CardTitle = styled(Title3Bold)`
    width: 300px;
    background: var(--primary__x-light);
    padding: 44px 30px;

    ${media.lessThan('medium')`
    width: unset;
    font-size: 18px;
    text-align: center;
    padding: 24px 20px;
    `}
`;

const CardBody = styled(SubtitleRegular)`
    padding: 44px 68px;
    white-space: pre-line;
    background: var(--grey__x-thin);
    width: 100%;

    strong {
        font-weight: 600;
    }

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    padding: 30px 20px 30px 20px;
    white-space: unset;
    color: var(--grey__medium);
    width: unset;
    `}
`;

const Information = () => {
    const { t } = useTranslation();
    const isMobileView = useIsMobileView();
    const cards = new Array(3).fill(0).map((_, index) => ({
        title: t(`shariya_compliance.information.cards.${index}.title`),
        body: `shariya_compliance.information.cards.${index}.body`,
    }));

    return (
        <Container>
            <Title
                underline={{
                    color: 'var(--secondary__medium)',
                    width: '58px',
                    height: isMobileView ? '6px' : '10px',
                    bottom: isMobileView ? '-6px' : undefined,
                }}
            >
                {t('shariya_compliance.information.title')}
            </Title>
            <Subtitle>{t('shariya_compliance.information.subtitle')}</Subtitle>
            {cards.map(({ title, body }) => (
                <Card key={title}>
                    <CardTitle>{title}</CardTitle>
                    <CardBody>
                        <Trans i18nKey={body} />
                    </CardBody>
                </Card>
            ))}
        </Container>
    );
};

export default Information;
