import DomCircle from 'components/styled/DomCircle';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { AdditionalRules, StylableComponent } from 'types/common';
import Icon, { IconProps } from '../svg-icon/Icon';
import { IconType } from '../svg-icon/icon-types';

const Container = styled.div<AdditionalRules>`
    position: relative;
    ${({ additional }) => additional}
`;

type CircleProps = IconProps & { top: string; left: string };

const SvgCircle = styled(Icon)<CircleProps>`
    position: absolute;
    top: ${({ top }) => top};
    left: ${({ left }) => left};
    z-index: 0;
`;

interface TextWithCircleProps {
    circle?: {
        left?: number;
        top?: number;
        size?: number;
        color?: 'blue' | 'red' | 'yellow' | string;
    };
    isSvg?: boolean;
    children: ReactNode;
}

const TextWithCircle = ({
    circle,
    isSvg,
    additional,
    children,
    className,
}: TextWithCircleProps & AdditionalRules & StylableComponent) => {
    isSvg = isSvg ?? true;
    return (
        <Container className={className} additional={additional}>
            {isSvg ? (
                <SvgCircle
                    left={(circle?.left || 0) + 'px'}
                    top={(circle?.top || 0) + 'px'}
                    name={`${circle?.color || 'blue'}-circle` as IconType}
                    size={`${circle?.size || 20}px`}
                />
            ) : (
                <DomCircle
                    size={(circle?.size || 20) + 'px'}
                    color={circle?.color || 'var(--alternative__medium)'}
                    top={circle?.top + 'px'}
                    left={circle?.left + 'px'}
                />
            )}

            {children}
        </Container>
    );
};

export default TextWithCircle;
