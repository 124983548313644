import styled from 'styled-components';
import { AdditionalRules } from '../../types/common';

const BackgroundCircle = styled.div<
    { size: string; color: string } & AdditionalRules
>`
    position: absolute;
    border-radius: 100%;
    height: ${({ size }) => size};
    width: ${({ size }) => size};
    background: ${({ color }) => color};
    ${({ additional }) => additional}
`;

export default BackgroundCircle;
