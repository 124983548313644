import { Button } from '@jouri/components';
import { Links } from 'common/constants/links';
import StyledLink from 'components/styled/StyledLink';
import config from 'config';
import { routesPaths } from 'config/routes';
import { useJouriOAuth } from 'jouri-oauth/JouriOAuth';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import ChangeLanguage from '../../change-language/changeLanguage';
import UnderlinedTextStyle, {
    UnderlinedTextStyleProps,
} from '../../styled/css/UnderlinedTextStyle';
import screenRelativeTo1440 from '../../styled/utility/screenRelativeTo1440';
import Icon from '../../svg-icon/Icon';
import MobileDrawerMenuContent from './MobileDrawerMenuContent';

const Nav = styled.nav`
    display: flex;
    align-items: flex-end;
    padding: 28px ${screenRelativeTo1440('120px')};
    box-sizing: border-box;

    ${media.lessThan('medium')`
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    padding: 0;
    margin:0;
    margin-left: 10px;
    `}
`;

const NavigationLink = styled(StyledLink)<
    {
        $active?: boolean;
    } & UnderlinedTextStyleProps
>`
    position: relative;
    ${({ $active }) => ($active ? UnderlinedTextStyle : '')}

    ${media.lessThan('medium')`
        display: none;
    `}
`;

const DesktopNavContainer = styled.div`
    display: flex;
    column-gap: 8px;
    align-items: center;

    ${media.lessThan('medium')`
        display: none;
    `}
`;

const DesktopButton = styled.button<{ $highlight?: boolean }>`
    border: 1px solid var(--grey__dark);
    border-radius: 4px;
    background: ${({ $highlight }) =>
        $highlight ? 'var(--grey__dark)' : 'none'};
    color: ${({ $highlight }) => ($highlight ? 'white' : 'var(--grey__dark)')};
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 0;
    height: 40px;
    cursor: pointer;

    &:first-of-type {
        margin-left: auto;
        margin-right: 16px;
    }

    ${media.lessThan('medium')`
    display: none;
    `}
`;

const HamburgerMenuButton = styled.button`
    margin-right: 10px;
    margin-top: 4px;
    border: 0;
    background-color: unset;
    color: var(--black);
`;

const ChangeLanguageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 39px;

    ${media.lessThan('medium')`
    display: none;
    `}
`;

const Modal = styled.div<{ show: boolean }>`
    z-index: 100;
    display: ${({ show }) => (show ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100vw;
    background: #ffffff;
    animation-name: dropdown;
    display: ${({ show }) => (show ? 'normal' : 'reverse')};
    animation-duration: 0.1s;
    box-sizing: border-box;

    @keyframes dropdown {
        0% {
            height: 0;
        }

        100% {
            height: 100%;
        }
    }
`;

const MobileLoginButton = styled.button`
    cursor: pointer;
    width: 78px;
    height: 24px;
    border-radius: 4px;
    color: #ffffff;
    border: none;
    background-color: var(--secondary__medium);
    font-size: 12px;
    font-weight: 500;
`;

const MobileLinksContainer = styled.div`
    display: none;
    align-items: center;
    column-gap: 8px;

    ${media.lessThan('medium')`
        display: flex;
    `}
`;

const GoToConsoleButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--secondary__medium);
    padding: 10px 20px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    border-radius: 4px;
    border: none;
    color: #fff;

    cursor: pointer;
    &:first-of-type {
        margin-left: auto;
        margin-right: 16px;
    }

    /* screen width is less than 768px (medium) */
    ${media.lessThan('medium')`
    margin-bottom: 30px;
    `}
`;

const Navigation = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const { language } = i18n;
    const location = useLocation();
    const { isAuthenticated } = useJouriOAuth();

    return (
        <Nav>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flex: 1,
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <StyledLink
                        to={routesPaths.root}
                        additional={{ margin: '0' }}
                    >
                        <Icon name="logo" height="40px" width="90px" />
                    </StyledLink>
                    <NavigationLink
                        to={routesPaths.howItWorks}
                        $active={location.pathname === routesPaths.howItWorks}
                        underline={{
                            color: 'var(--secondary__medium)',
                            width: '50%',
                            height: '4px',
                            bottom: '-4px',
                        }}
                        underlineAdditional={{ marginLeft: '0' }}
                        additional={{ marginLeft: '48px' }}
                    >
                        {t('navigation.how_it_works')}
                    </NavigationLink>
                    <NavigationLink
                        $highlight
                        to={routesPaths.forBusiness}
                        $active={location.pathname === routesPaths.forBusiness}
                        underline={{
                            color: 'var(--secondary__medium)',
                            width: '50%',
                            height: '4px',
                            bottom: '-4px',
                        }}
                    >
                        {t('navigation.for_business')}
                    </NavigationLink>
                </div>
                <DesktopNavContainer>
                    {isAuthenticated && (
                        <GoToConsoleButton
                            type="button"
                            onClick={() => {
                                window.location.href = `${
                                    config().userDashboardUrl
                                }/?lang=${language}`;
                            }}
                        >
                            {t('navigation.go_to_console')}
                        </GoToConsoleButton>
                    )}
                    {!isAuthenticated && (
                        <>
                            <Button
                                onClick={() =>
                                    window.open(Links.BECOME_MERCHANT, '_blank')
                                }
                            >
                                {t('navigation.become_merchant')}
                            </Button>
                            <DesktopButton
                                type="button"
                                onClick={() => {
                                    window.location.href = `/onboarding?lang=${language}`;
                                }}
                            >
                                {t('navigation.sign_up')}
                            </DesktopButton>
                            <DesktopButton
                                $highlight
                                type="button"
                                onClick={() => {
                                    window.location.href = `${
                                        config().userDashboardUrl
                                    }/?lang=${language}`;
                                }}
                            >
                                {t('navigation.login')}
                            </DesktopButton>
                        </>
                    )}
                    <ChangeLanguageContainer>
                        <ChangeLanguage />
                    </ChangeLanguageContainer>
                </DesktopNavContainer>
            </div>
            <MobileLinksContainer>
                <Button
                    size="small"
                    noPadding
                    onClick={() => window.open(Links.BECOME_MERCHANT, '_blank')}
                >
                    {t('navigation.become_merchant')}
                </Button>
                <MobileLoginButton
                    onClick={() => {
                        window.location.href = `${
                            config().userDashboardUrl
                        }/?lang=${language}`;
                    }}
                >
                    {t('navigation.login')}
                </MobileLoginButton>
                <HamburgerMenuButton onClick={() => setMenuOpen(true)}>
                    <Icon name="hamburger" height="20px" width="20px" />
                </HamburgerMenuButton>
            </MobileLinksContainer>
            <Modal show={menuOpen}>
                <MobileDrawerMenuContent onClose={() => setMenuOpen(false)} />
            </Modal>
        </Nav>
    );
};

export default Navigation;
