import styled from 'styled-components';

const Button = styled.button`
    width: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    border-radius: 4px;
    border: none;
    cursor: pointer;
`;

export default Button;
