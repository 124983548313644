import styled from 'styled-components';
import {
    SubtitleMedium,
    SubtitleRegular,
    Title2Medium,
    Title3Medium,
} from '../../components/styled/typography';
import UnderlinedTextStyle from '../../components/styled/css/UnderlinedTextStyle';
import { useTranslation } from 'react-i18next';
import media from 'styled-media-query';
import useIsMobileView from '../../common/hooks/useIsMobileView';

const Container = styled.section`
    padding: 96px ${`calc(50vw - 400px)`};
    background: var(--secondary__thin__20);

    ${media.lessThan('medium')`
    padding-bottom: 10px;
    padding-top: 50px;
    background: var(--secondary__thin__OP40);

    `}
`;

const Title = styled(Title2Medium)`
    ${UnderlinedTextStyle}

    ${media.lessThan('medium')`
    font-size: 24px;
    left: 20px;
    `}
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;

    ${media.lessThan('medium')`
     padding: 20px;
    `}
`;

const StrongText = styled(Title3Medium)`
    margin-top: 45px;

    ${media.lessThan('medium')`
    font-size: 18px;
    margin-top: 17px;
    `}
`;

const Text = styled(SubtitleRegular)`
    margin-top: 58px;
    white-space: pre-line;

    ${media.lessThan('medium')`
    padding-right: 15px;
    font-size: 16px;
    margin-top: 17px;
    `}
`;

const PunchText = styled(SubtitleMedium)``;

const Vision = () => {
    const { t } = useTranslation();
    const isMobileView = useIsMobileView();
    return (
        <Container>
            <Title
                underline={{
                    color: 'var(--secondary__medium)',
                    width: '58px',
                    height: isMobileView ? '6px' : '10px',
                }}
            >
                {t('about_us.vision.title')}
            </Title>
            <Body>
                <StrongText>{t('about_us.vision.body.strong')}</StrongText>
                <Text>{t('about_us.vision.body.text')}</Text>
                <PunchText>{t('about_us.vision.body.punch')}</PunchText>
            </Body>
        </Container>
    );
};

export default Vision;
