import { Body1Medium } from '../../components/styled/typography';
import { useTranslation } from 'react-i18next';
import * as S from './ApplicationForm.style';
import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Entity } from 'components/entity/Details';
import axios from 'axios';
import { message } from 'antd';
import 'antd/es/message/style/index.css';

const ALLOWED_FILE_TYPES = [
    'application/pdf',
    'application/msword',
    'application/mspowerpoint',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

interface ApplicationFormType {
    name?: string;
    lastname?: string;
    email?: string;
    job?: string;
    fileName?: string;
    fileBase64?: string;
    fileType?: string;
}

const schema = yup.object().shape({
    name: yup.string().required(),
    lastname: yup.string().required(),
    email: yup.string().email().required(),
    job: yup.string(),
});

interface ApplicationFormProps {
    entity: Entity;
}

const ApplicationForm = ({ entity }: ApplicationFormProps) => {
    const { t } = useTranslation();
    const [fileBase64, setFileBase64] = useState();
    const [fileType, setFileType] = useState();
    const formik = useFormik<ApplicationFormType>({
        initialValues: {
            name: '',
            lastname: '',
            email: '',
            job: entity.title,
            fileName: '',
        },
        validationSchema: schema,
        onSubmit: async (values, helpers) => {
            try {
                let options = {};
                if (fileBase64 && fileType) {
                    options = {
                        fileBase64,
                        fileType,
                    };
                }
                const payload = JSON.stringify({
                    ...values,
                    ...options,
                });

                await axios.post('/apply-job', payload, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    baseURL: '/api/marketing',
                });
                message.success(
                    t('job_application.application_form.formSuccess_message')
                );
                helpers.resetForm();
            } catch (error) {
                alert(t('general.service.errorMessage'));
                helpers.resetForm();
            }
        },
    });

    const validateForm = useCallback(async () => {
        await formik.validateForm();
    }, [formik]);

    useEffect(() => {
        validateForm();
    }, []);

    const triggerUploadFileBtn = useCallback(() => {
        const fileInput = document.getElementById(
            'fileUpload'
        ) as HTMLInputElement;
        fileInput.click();
    }, []);

    const handleSelectFile = useCallback(
        (event: any) => {
            const file = event.currentTarget.files[0];
            const fileName = document.getElementById(
                'fileName'
            ) as HTMLInputElement;

            if (!file) {
                return;
            }

            if (file.size > 1024 * 1024 * 4) {
                alert(
                    t(
                        'job_application.application_form.validation.maxFileSize_message'
                    )
                );

                const fileInput = document.getElementById(
                    'fileUpload'
                ) as HTMLInputElement;

                fileName.value = '';

                fileInput.files = null;
                fileInput.value = '';
                return;
            }

            fileName.value = file.name;

            const fileReader = new FileReader();
            let base64File;
            fileReader.onload = function (event: any) {
                base64File = event.target.result;
                setFileBase64(base64File);
                setFileType(file.type);
            };
            fileReader.readAsDataURL(file);
            formik.setFieldValue('fileName', file.name);
        },
        [setFileBase64, formik]
    );
    return (
        <S.Container>
            <S.FormContainer>
                <S.Form onSubmit={formik.handleSubmit}>
                    <S.Title>
                        {t('job_application.application_form.title')}
                    </S.Title>
                    <S.Label compact>
                        <S.LabelText>
                            {t('job_application.application_form.labels.name')}
                        </S.LabelText>
                        <S.Input
                            name="name"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                        />
                    </S.Label>
                    <S.Label compact>
                        <S.LabelText>
                            {t(
                                'job_application.application_form.labels.surname'
                            )}
                        </S.LabelText>
                        <S.Input
                            name="lastname"
                            onChange={formik.handleChange}
                            value={formik.values.lastname}
                        />
                    </S.Label>
                    <S.Label>
                        <S.LabelText>
                            {t(
                                'job_application.application_form.labels.e-mail'
                            )}
                        </S.LabelText>
                        <S.Input
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                        />
                    </S.Label>
                    <S.Label>
                        <S.LabelText>
                            {t('job_application.application_form.labels.team')}
                        </S.LabelText>

                        <S.Input name="job" disabled value={entity.title} />
                    </S.Label>
                    <S.CVButton onClick={triggerUploadFileBtn}>
                        <Body1Medium>
                            {t(
                                'job_application.application_form.labels.upload'
                            )}
                        </Body1Medium>
                    </S.CVButton>
                    <S.SelectedFile>{`${formik.values.fileName}`}</S.SelectedFile>
                    <div style={{ display: 'none' }}>
                        <input type="text" id="fileName" disabled />
                        <input
                            type="file"
                            name="file"
                            onChange={handleSelectFile}
                            accept={ALLOWED_FILE_TYPES.join(',')}
                            id="fileUpload"
                        />
                    </div>
                    <S.SubmitButton type="submit" disabled={!formik.isValid}>
                        {t('job_application.application_form.labels.submit')}
                    </S.SubmitButton>
                </S.Form>
            </S.FormContainer>
        </S.Container>
    );
};

export default ApplicationForm;
